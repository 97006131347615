import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class QueryExecutorUrlProvider {
  constructor(@Inject(environmentToken) private environment: Environment) {}
  get simpleQueryUrl(): string {
    return `${this.environment.apiUrl}${this.environment.apiPath}/${this.environment.queryMicroPath}/simple`;
  }
  get complexQueryUrl(): string {
    return `${this.environment.apiUrl}${this.environment.apiPath}/${this.environment.queryMicroPath}`;
  }
  get liveQueryUrl(): string {
    return `${this.environment.apiUrl}${this.environment.apiPath}/${this.environment.queryMicroPath}/live`;
  }
  get liveDataQueryUrl(): string {
    return `${this.environment.apiUrl}${this.environment.apiPath}/${this.environment.queryMicroPath}/live/node-data`;
  }
}
