interface RouteConstant {
  id: string;
  name: string;
  path: string;
  icon?: string;
  regex: string;
}
export const GLOBAL_ADMIN_ROUTE: RouteConstant = {
  id: 'global-administration',
  name: 'Global Administration',
  path: '/global-administration',
  icon: 'or-icon-admin',
  regex: '/global-administration'
};
export const GLOBAL_ADMIN_USER_ROUTE: RouteConstant = {
  id: 'global-users',
  name: 'Global Users',
  path: '/global-administration/users',
  regex: '/global-administration/users'
};
export const GLOBAL_ADMIN_MANAGING_COMPANY_ROUTE: RouteConstant = {
  id: 'global-managing-companies',
  name: 'Global Managing Companies',
  path: '/global-administration/managing-company',
  regex: '/global-administration/managing-company'
};
export const GLOBAL_ADMIN_EMERGENCY_LIGHTING_CALENDAR_ROUTE: RouteConstant = {
  id: 'global-emergency-lighting-calendar',
  name: 'Global Emergency Lighting Calendar',
  path: '/global-administration/emergency-lighting-calendar',
  regex: '/global-administration/emergency-lighting-calendar'
};
export const GLOBAL_ADMIN_PACKET_STREAMER_ROUTE: RouteConstant = {
  id: 'packet-stream',
  name: 'Packet Stream',
  path: '/global-administration/packet-stream',
  regex: '/global-administration/packet-stream'
};
export const GLOBAL_ADMIN_OTAP_UPGRADES: RouteConstant = {
  id: 'otap-upgrades',
  name: 'OTAP Upgrades',
  path: '/global-administration/otap-upgrades',
  regex: '/global-administration/otap-upgrades'
};

export const GLOBAL_ADMIN_OTAP_UPGRADES_GATEWAY: RouteConstant = {
  id: 'otap-upgrades-gateway',
  name: 'OTAP Upgrades',
  path: '/global-administration/otap-upgrades/job-information',
  regex: '/global-administration/otap-upgrades/job-information'
};

export const ADMIN_ROUTE: RouteConstant = {
  id: 'administration',
  name: 'Administration',
  path: '/buildings/:buildingId/administration',
  icon: 'or-icon-admin',
  regex: '/buildings/\\d+/administration'
};
export const BUILDING_ADMIN_ROUTE: RouteConstant = {
  id: 'building',
  name: 'Building',
  path: '/buildings/:buildingId/administration/building',
  regex: '/buildings/\\d+/administration/building'
};
export const FLOORS_ADMIN_ROUTE: RouteConstant = {
  id: 'floors',
  name: 'Floors',
  path: '/buildings/:buildingId/administration/floors',
  regex: '/buildings/\\d+/administration/floors'
};
export const GATEWAYS_ADMIN_ROUTE: RouteConstant = {
  id: 'gateways',
  name: 'Gateways',
  path: '/buildings/:buildingId/administration/gateways',
  regex: '/buildings/\\d+/administration/gateways'
};
export const MOBILE_TOKENS_ADMIN_ROUTE: RouteConstant = {
  id: 'mobile-tokens',
  name: 'Mobile Tokens',
  path: '/buildings/:buildingId/administration/mobile-tokens',
  regex: '/buildings/\\d+/administration/mobile-tokens'
};
export const TENANT_GROUPS_ADMIN_ROUTE = {
  id: 'tenants',
  name: 'Tenants',
  path: '/buildings/:buildingId/administration/tenants',
  regex: '/buildings/\\d+/administration/tenants'
};
export const ROLES_ADMIN_ROUTE: RouteConstant = {
  id: 'roles',
  name: 'Roles',
  path: '/buildings/:buildingId/administration/roles',
  regex: '/buildings/\\d+/administration/roles'
};
export const TOKENS_ADMIN_ROUTE: RouteConstant = {
  id: 'tokens',
  name: 'Tokens',
  path: '/buildings/:buildingId/administration/tokens',
  regex: '/buildings/\\d+/administration/tokens'
};
export const USERS_ADMIN_ROUTE: RouteConstant = {
  id: 'users',
  name: 'Users',
  path: '/buildings/:buildingId/administration/users',
  regex: '/buildings/\\d+/administration/users'
};
export const CALENDAR_ADMIN_ROUTE: RouteConstant = {
  id: 'elmt-schedules-calendar',
  name: 'Calendar',
  path: '/buildings/:buildingId/administration/calendar',
  regex: '/buildings/\\d+/administration/calendar'
};
export const EMERGENCY_LIGHTING_ADMIN_ROUTE: RouteConstant = {
  id: 'emergency-lighting-admin',
  name: 'Emergency Lighting',
  path: '/buildings/:buildingId/administration/emergency-lighting',
  regex: '/buildings/\\d+/administration/emergency-lighting'
};

export const ANALYTICS_ROUTE: RouteConstant = {
  id: 'analytics',
  name: 'Analytics',
  path: '/buildings/:buildingId/:floorId/analytics',
  icon: 'or-icon-glasses',
  regex: '/buildings/\\d+/\\d+/analytics'
};
export const BEACONS_ROUTE: RouteConstant = {
  id: 'beacons',
  name: 'Bluetooth Beacons',
  path: '/buildings/:buildingId/:floorId/beacons',
  icon: 'or-icon-beacon',
  regex: '/buildings/\\d+/\\d+/beacons'
};
export const LIGHTING_CONFIGURATION_ROUTE: RouteConstant = {
  id: 'lighting-configuration',
  name: 'Lighting Configuration',
  path: '/buildings/:buildingId/:floorId/lighting-configuration',
  icon: 'or-icon-lighting-config',
  regex: '/buildings/\\d+/\\d+/lighting-configuration'
};
export const BUILDINGS_ROUTE: RouteConstant = {
  id: 'buildings',
  name: 'Buildings',
  path: '/buildings',
  regex: '/buildings'
};
export const CIRCADIAN_CURVES_ROUTE: RouteConstant = {
  id: 'circadian-curves',
  name: 'Circadian Curves',
  path: '/buildings/:buildingId/circadian-curves',
  icon: 'or-icon-daylight-change',
  regex: '/buildings/\\d+/circadian-curves'
};

export const MAP_NODES_ROUTE: RouteConstant = {
  id: 'map-nodes',
  name: 'Map Nodes',
  path: '/buildings/:buildingId/:floorId/map-nodes',
  regex: '/buildings/\\d+/\\d+/map-nodes',
  icon: 'or-icon-mapping'
};

export const DASHBOARD_ROUTE: RouteConstant = {
  id: 'dashboard',
  name: 'Dashboard',
  path: '/buildings/:buildingId/dashboard',
  icon: 'or-icon-dashboard',
  regex: '/buildings/\\d+/dashboard'
};

export const DASHBOARD_GENERAL_ROUTE: RouteConstant = {
  id: 'general-dashboard',
  name: 'General Dashboard',
  path: '/buildings/:buildingId/dashboard/general',
  regex: '/buildings/\\d+/dashboard/general'
};

export const DASHBOARD_ENERGY_ROUTE: RouteConstant = {
  id: 'energy-dashboard',
  name: 'Energy Dashboard',
  path: '/buildings/:buildingId/dashboard/energy',
  regex: '/buildings/\\d+/dashboard/energy'
};

export const DASHBOARD_OCCUPANCY_ROUTE: RouteConstant = {
  id: 'occupancy-dashboard',
  name: 'Occupancy Dashboard',
  path: '/buildings/:buildingId/dashboard/occupancy',
  regex: '/buildings/\\d+/dashboard/occupancy'
};

export const DASHBOARD_MAINTENANCE_ROUTE: RouteConstant = {
  id: 'maintenance-dashboard',
  name: 'Maintenance Dashboard',
  path: '/buildings/:buildingId/dashboard/maintenance',
  regex: '/buildings/\\d+/dashboard/maintenance'
};

export const SENSORNODES_ROUTE: RouteConstant = {
  id: 'sensor-nodes',
  name: 'Sensor Nodes',
  path: '/buildings/:buildingId/:floorId/sensor-nodes',
  regex: '/buildings/\\d+/\\d+/sensor-nodes',
  icon: 'or-icon-node'
};
export const EMERGENCY_LIGHTING_BASE_ROUTE: RouteConstant = {
  id: 'emergency-lighting',
  name: 'Emergency Lighting',
  path: '/buildings/:buildingId/emergency-lighting',
  regex: '/buildings/\\d+(/\\d+)?/emergency-lighting.*',
  icon: 'or-icon-emergency'
};
export const EMERGENCY_LIGHTING_SCHEDULES_ROUTE: RouteConstant = {
  id: 'emergency-lighting-schedules',
  name: 'Emergency Lighting Schedules',
  path: '/buildings/:buildingId/emergency-lighting',
  regex: '/buildings/\\d+/emergency-lighting/schedules'
};
export const EMERGENCY_LIGHTING_LOGS_ROUTE: RouteConstant = {
  id: 'emergency-lighting-logs',
  name: 'Emergency Lighting Logs',
  path: '/buildings/:buildingId/emergency-lighting/logs',
  regex: '/buildings/\\d+/emergency-lighting/logs'
};
export const EMERGENCY_LIGHTING_REPORTS_ROUTE: RouteConstant = {
  id: 'emergency-lighting-reports',
  name: 'Emergency Lighting Reports',
  path: '/buildings/:buildingId/emergency-lighting/reports',
  regex: '/buildings/\\d+/emergency-lighting/reports'
};
export const EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE: RouteConstant = {
  id: 'emergency-lighting-manual-tests',
  name: 'Emergency Lighting Manual Tests',
  path: '/buildings/:buildingId/emergency-lighting/:floorId/manual-tests',
  regex: '/buildings/\\d+/emergency-lighting/\\d+/manual-tests'
};
export const INTEGRATIONS_AREAS_ROUTE: RouteConstant = {
  id: 'integration-areas',
  name: 'Areas',
  path: '/buildings/:buildingId/:floorId/integrations/areas',
  regex: '/buildings/\\d+/\\d+/integrations/areas'
};
export const INTEGRATIONS_ROUTE: RouteConstant = {
  id: 'integrations',
  name: 'Integrations',
  path: '/buildings/:buildingId/:floorId/integrations',
  icon: 'or-icon-plug',
  regex: '/buildings/\\d+/\\d+/integrations.*'
};
export const LOGIN_ROUTE: RouteConstant = {
  id: 'login',
  name: 'Log in',
  path: '/login',
  regex: '/login'
};
export const MAIN_ROUTE: RouteConstant = { id: 'main', name: 'Main', path: '', regex: '.*' };
export const NEW_BUILDING_ROUTE: RouteConstant = {
  id: 'new',
  name: 'New Building',
  path: '/buildings/new',
  regex: '/buildings/new'
};
export const SCHEDULES_ROUTE: RouteConstant = {
  id: 'schedules',
  name: 'Schedules',
  path: '/buildings/:buildingId/schedules',
  icon: 'or-icon-watch',
  regex: '/buildings/\\d+/schedules'
};
export const USER_ROUTE: RouteConstant = {
  id: 'user',
  name: 'User',
  path: '/user',
  regex: '/user/\\W'
};

export const DDL_ROUTE: RouteConstant = {
  id: 'ddl',
  name: 'DDL',
  path: '/buildings/:buildingId/administration/ddl',
  regex: '/buildings/\\d+/administration/ddl'
};
