<div class='w-full p-4 pb-0'>
  <form class='flex flex-col sm:flex-row' [formGroup]='filterForm'>
    <mat-form-field class='dashboard-filter basis-1/6 px-2 max-h-[82px]'>
      <mat-label>Filter by date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input formControlName='startDate' matStartDate placeholder="Start date">
        <input formControlName='endDate' matEndDate placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker (closed)="onCloseDatePicker()"></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field class='dashboard-filter basis-5/12 px-2 max-h-[82px]'>
      <mat-label>Filter by floors</mat-label>
      <mat-select class='max-h-[30px]' [formControl]="floors" multiple>
        <mat-select-trigger class='max-h-[30px]'>
          <mat-chip-set>
            @for(floor of floors.value; track floor) {
              <mat-chip [ngClass]="'chip-color-green'" [removable]="true" (removed)="onFloorRemove(floor)">
                {{ floor.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            }
          </mat-chip-set>
        </mat-select-trigger>
        @for(floor of allFloors; track floor) {
          <mat-option [value]="floor">{{floor.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class='dashboard-filter basis-5/12 px-2 max-h-[82px]'>
      <mat-label>Filter by tags</mat-label>
      <mat-select class='max-h-[30px]' [formControl]="tags" multiple>
        <mat-select-trigger class='max-h-[30px]'>
          <mat-chip-set>
            @for(tag of tags.value; track tag) {
              <mat-chip [ngClass]="'chip-color-red'" [removable]="true" (removed)="onTagRemove(tag)">
                {{ tag.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            }
          </mat-chip-set>
        </mat-select-trigger>
        @for(tag of allTags; track tag) {
          <mat-option [value]="tag">{{tag.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class='flex flex-row-reverse'>
      <button [disabled]='filterForm.invalid' class='action-button apply-btn mx-1' matTooltip='Apply filters' (click)='apply()' mat-button color='primary'>
        <i class="material-icons">fact_check</i>
      </button>
      <button class='action-button reset-btn mx-1' matTooltip='Reset Filters' (click)='reset()' mat-button color='warn'>
        <i class="material-icons">refresh</i>
      </button>
    </div>
  </form>
</div>
