import { Tab } from '@app/shared/components/tabs/tab.interface';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { Building } from '@app/shared/models/building.interface';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { User } from '@app/shared/models/user.interface';

export const administrationTabs: Tab[] = [
  {
    name: 'Building',
    path: './building',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_BUILDING)
  },
  {
    name: 'Gateways',
    path: '../administration/gateways',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_GATEWAYS)
  },
  {
    name: 'Floors',
    path: '../administration/floors',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_FLOORS)
  },
  {
    name: 'Users',
    path: '../administration/users',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_USERS)
  },
  {
    name: 'Roles',
    path: '../administration/roles',
    isAuthorized: hasOneOfBuildingAuthority([BuildingAuthorityType.MANAGE_ROLES, BuildingAuthorityType.MANAGE_USERS])
  },
  {
    name: 'Tokens',
    path: '../administration/tokens',
    isAuthorized: hasAuthority(GlobalAuthority.MANAGE_TOKEN_USERS)
  },
  {
    name: 'Mobile Tokens',
    path: '../administration/mobile-tokens',
    isAuthorized: hasAuthority(GlobalAuthority.MANAGE_MOBILE_TOKEN)
  },
  // FIXME: Hide the calendar tab from administration view until the endpoints for this in backend are merged
  // {
  //   name: 'Calendar',
  //   path: '../administration/calendar',
  //   isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_BUILDING)
  // },
  {
    name: 'Emergency Lighting',
    path: '../administration/emergency-lighting',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_BUILDING)
  },
  {
    name: 'Tenants',
    path: '../administration/tenants',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_TENANT)
  },
  {
    name: 'DDL',
    path: '../administration/ddl',
    isAuthorized: hasBuildingAuthority(BuildingAuthorityType.MANAGE_DDL)
  }
];

function hasBuildingAuthority(
  authority: BuildingAuthorityType
): (user: User, building: Building) => boolean {
  return (user: User, building: Building) =>
    user.buildingAuthorizations.some((ba) => {
      if (ba.buildingId === building.id) {
        return ba.authorities.some(
          (a) => a.toString() === authority.toString()
        );
      }
      return false;
    });
}

function hasOneOfBuildingAuthority(
  authority: BuildingAuthorityType[]
): (user: User, building: Building) => boolean {
  return (user: User, building: Building) =>
    user.buildingAuthorizations.some((ba) => {
      if (ba.buildingId === building.id) {
        return ba.authorities.some(
          (a) => authority.filter((auth) => auth.toString() === a.toString()).length > 0
        );
      }
      return false;
    });
}

function hasAuthority(authority: GlobalAuthority): (user: User) => boolean {
  return (user: User) =>
    user.globalAuthorities.some((ga) => {
      return ga.toString() === authority.toString();
    });
}
