import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {User} from "@app/shared/models/user.interface";
import {GlobalAuthority} from "@app/shared/models/global-authority";
import {ManagingCompanyService} from "@app/shared/services/managing-company.service";
import {Observable} from "rxjs";
import {ManagingCompany} from "@app/shared/models/building.interface";

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

    form: FormGroup;
    user: User;
    managingCompanies$: Observable<ManagingCompany[]>;
    globalAuthorities: GlobalAuthority[];

    constructor(private fb: FormBuilder,
                @Inject(MAT_DIALOG_DATA) user: User,
                private dialogRef: MatDialogRef<UserDialogComponent>,
                private managingCompanyService: ManagingCompanyService) {

        this.user = user;
        this.managingCompanies$ = this.managingCompanyService.getManagingCompanies();
        this.globalAuthorities = GlobalAuthority.all();

        this.form = this.fb.group({
            emailAddress: [{value: user.emailAddress, disabled: true}],
            authMethod: [{value: user.authMethod, disabled: true}],
            managingCompany: [user.managingCompanyId],
            globalAuthorities: [user.globalAuthorities]
        });
    }

    ngOnInit(): void {

    }

    close() {
        this.dialogRef.close();
    }

    save() {
        console.log("save");
        console.log(this.form.value);
        this.dialogRef.close(this.form.value);
    }
}