import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GlobalAdministrationComponent } from '@app/global-administration/layout/global-administration.component';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { GlobalAdministrationUsersComponent } from '@app/global-administration/user/user.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { UserDialogComponent } from '@app/global-administration/user/user-dialog/user-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { GlobalAdministrationManagingCompanyComponent } from '@app/global-administration/managing-company/managing-company.component';
import { MatSelectModule } from '@angular/material/select';
import { ManagingCompanyDialogComponent } from '@app/global-administration/managing-company/managing-company-dialog/managing-company-dialog.component';
import { GlobalAdministrationPacketStreamerComponent } from '@app/global-administration/packet-streamer/packet-streamer.component';
import { MatRadioModule } from '@angular/material/radio';
import { GlobalAdministrationEmergencyLightingCalendarComponent } from '@app/global-administration/emergency-lighting-calendar/emergency-lighting-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { GlobalAdministrationOtapUpgradesComponent } from '@app/global-administration/otap-upgrades/otap-upgrades.component';
import { JobInformationComponent } from '@app/global-administration/otap-upgrades/job-information/job-information.component';
import { GatewayDetailsComponent } from './otap-upgrades/job-information/gateway-details/gateway-details.component';
import { JobTasksComponent } from './otap-upgrades/job-information/job-tasks/job-tasks.component';
import { AdministrationModule } from '@app/administration/administration.module';
import { JobTasksListComponent } from './otap-upgrades/job-information/job-tasks/job-tasks-list/job-tasks-list.component';
import { JobTasksSummaryComponent } from './otap-upgrades/job-information/job-tasks/job-tasks-summary/job-tasks-summary.component';
import { JobTasksTileComponent } from './otap-upgrades/job-information/job-tasks/job-tasks-tile/job-tasks-tile.component';
import { FormJobTaskComponent } from './otap-upgrades/job-information/job-tasks/form-job-task/form-job-task.component';

@NgModule({
  declarations: [
    GlobalAdministrationComponent,
    GlobalAdministrationUsersComponent,
    UserDialogComponent,
    GlobalAdministrationManagingCompanyComponent,
    ManagingCompanyDialogComponent,
    GlobalAdministrationPacketStreamerComponent,
    GlobalAdministrationEmergencyLightingCalendarComponent,
    GlobalAdministrationOtapUpgradesComponent,
    JobInformationComponent,
    GatewayDetailsComponent,
    JobTasksComponent,
    JobTasksListComponent,
    JobTasksSummaryComponent,
    JobTasksTileComponent,
    FormJobTaskComponent
  ],
  imports: [
    SharedComponentsModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatButtonToggleModule,
    AdministrationModule
  ],
  exports: [],
  providers: []
})
export class GlobalAdministrationModule {}
