import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Role } from '@app/shared/models/role.interface';
import { Building } from '@app/shared/models/building.interface';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { RoleService } from '@services/role.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ToastService } from '@services/toast/toast.service';
import { SecurityService } from '@services/security.service';
import { Observable } from 'rxjs';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';

@Component({
  selector: 'app-form-role-details',
  templateUrl: './form-role-details.component.html',
  styleUrls: ['./form-role-details.component.scss']
})
export class FormRoleDetailsComponent implements OnInit {
  @Input() role: Role;
  @Input() index: number;
  @Input() isNew = false;
  @Input() building: Building;
  @Input() isBusy = true;

  @Output() valueChanged = new EventEmitter<void>();

  formRole: FormGroup;
  authorityTypes = [
    BuildingAuthorityType.ALL,
    BuildingAuthorityType.VIEW,
    BuildingAuthorityType.ANALYTICS,
    BuildingAuthorityType.HIGH_RESOLUTION_ANALYTICS,
    BuildingAuthorityType.CONTROL_LIGHTS,
    BuildingAuthorityType.MANAGE_NODES,
    BuildingAuthorityType.MANAGE_FLOORS,
    BuildingAuthorityType.MANAGE_GATEWAYS,
    BuildingAuthorityType.MANAGE_BACNET_AREAS,
    BuildingAuthorityType.MANAGE_BLUETOOTH_BEACONS,
    BuildingAuthorityType.MANAGE_LIGHTING_CONFIGURATION,
    BuildingAuthorityType.MANAGE_TAGS,
    BuildingAuthorityType.MANAGE_USERS,
    BuildingAuthorityType.MANAGE_ROLES,
    BuildingAuthorityType.MANAGE_BUILDING,
    BuildingAuthorityType.MANAGE_SCHEDULES,
    BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING,
    BuildingAuthorityType.MAINTENANCE,
    BuildingAuthorityType.MANAGE_CIRCADIAN_RHYTHM,
    BuildingAuthorityType.MANAGE_MAINTENANCE_UPDATES,
    BuildingAuthorityType.MANAGE_DDL,
    BuildingAuthorityType.MANAGE_TENANT,
    BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS
  ];
  constructor(
    private fb: FormBuilder,
    private roleService: RoleService,
    private toast: ToastService,
    private dialogService: ConfirmationDialogService,
    private securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.initForm(this.role);
  }

  initForm(role: Role): void {
    this.hasRolePermission().subscribe((check) => {
      this.formRole = this.fb.group({
        id: [role ? role.id : null],
        name: [{ value: role ? role.name : '', disabled: !check || this.isBusy }, Validators.required],
        authorityTypes: this.resolveAuthorityTypes(role, check)
      });
    });
  }

  hasRolePermission(): Observable<boolean> {
    return this.securityService.isAuthorizedForBuilding(BuildingAuthorityType.MANAGE_ROLES.value, this.building.id);
  }

  resolveAuthorityTypes(role: Role, check: boolean): FormArray {
    const formArray = new FormArray([]);
    this.authorityTypes.forEach((type: BuildingAuthorityType, index) => {
      let formControl = new FormControl({
        value: this.hasAuthority(role, type.value),
        disabled: !check || this.checkAuthorityTypeDisability(role, type.value)
      });
      formArray.push(formControl);
    });
    return formArray;
  }

  checkAuthorityTypeDisability(role: Role, authorityType: string): boolean {
    if (authorityType !== BuildingAuthorityType.ALL.toString() || !authorityType) {
      return this.hasAuthority(role, BuildingAuthorityType.ALL.toString());
    } else {
      return false;
    }
  }

  hasAuthority(role: Role, authorityType: string): boolean {
    return (
      role?.authorityTypes.filter(
        (type: any) => type === authorityType || type === BuildingAuthorityType.ALL.toString()
      ).length > 0
    );
  }

  get authorityTypeControls(): FormArray {
    return this.formRole ? (this.formRole.get('authorityTypes') as FormArray) : new FormArray<any>([]);
  }

  authorityTypeChange(previousValue: boolean, type: string): void {
    if (type === BuildingAuthorityType.ALL.toString()) {
      (this.formRole.get('authorityTypes') as FormArray).controls.forEach((val, index) => {
        if (index !== 0) {
          val.setValue(!previousValue);
          if (previousValue) {
            val.enable();
          } else {
            val.disable();
          }
        }
      });
    }
  }

  addRole(): void {
    let formValue = this.formRole.value;
    formValue = this.mapToAuthorities(formValue);
    this.roleService.addRole(this.building.id, formValue).subscribe({
      next: (res) => {
        this.initForm(null);
        this.toast.success({
          message: `Successfully created the role: ${res.name}`,
          dataCy: `add-success-toast`
        });
        this.valueChanged.emit();
      },
      error: () =>
        this.toast.error({
          message: 'Failed to create the role. please try again.',
          dataCy: `add-error-toast`
        })
    });
  }
  updateRole(): void {
    let formValue = this.formRole.value;
    formValue = this.mapToAuthorities(formValue);
    formValue.buildingId = this.building.id;
    this.roleService.updateRole(formValue).subscribe({
      next: () => {
        this.toast.success({
          message: `Successfully updated the role: ${formValue.name}`,
          dataCy: `update-success-toast`
        });
        this.valueChanged.emit();
      },
      error: () =>
        this.toast.error({
          message: 'Failed to update the role. please try again.',
          dataCy: `update-error-toast`
        })
    });
  }
  resetRole(): void {
    const data = new ConfirmDialogData(`Are you sure you want to reset the role?`);
    this.dialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.initForm(this.role);
      }
    });
  }
  deleteRole(): void {
    const data = new ConfirmDialogData(`Are you sure you want to delete the role: ${this.role.name}`);
    this.dialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.roleService.deleteRole(this.role.id).subscribe({
          next: () => {
            this.toast.success({
              message: `Successfully deleted the role: ${this.role.name}`,
              dataCy: `delete-success-toast`
            });
            this.valueChanged.emit();
          },
          error: () =>
            this.toast.error({
              message: `Failed to delete the role: ${this.role.name}`,
              dataCy: `delete-error-toast`
            })
        });
      }
    });
  }

  mapToAuthorities(formValue: any): any {
    if (formValue && formValue.authorityTypes.length === 1) {
      formValue.authorityTypes = [this.authorityTypes[0].value];
    } else {
      formValue.authorityTypes = formValue.authorityTypes
        .map((val, index) => (val ? this.authorityTypes[index].value : 0))
        .filter((val) => val !== 0);
    }
    return formValue;
  }
}
