import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { MatChip, MatChipGrid, MatChipRemove, MatChipSet } from '@angular/material/chips';
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { MatButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { Floor } from '@app/shared/models/floor.interface';
import { Tag } from '@app/shared/models/tag.interface';
import { MatTooltip } from '@angular/material/tooltip';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { DashboardStateService } from './dashboard-state.service';

export interface DashboardFilterForm {
  startDate: FormControl<Date>;
  endDate: FormControl<Date>;
  floors: FormControl<Floor[]>;
  tags: FormControl<Tag[]>;
}

@Component({
  selector: 'app-dashboard-filter',
  standalone: true,
  imports: [
    FormsModule,
    MatChip,
    MatChipGrid,
    MatChipRemove,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatFormField,
    MatIcon,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    MatStartDate,
    MatSuffix,
    NgForOf,
    ReactiveFormsModule,
    MatIconButton,
    AsyncPipe,
    MatButton,
    MatChipSet,
    NgClass,
    MatTooltip,
    MatMiniFabButton
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
  templateUrl: './dashboard-filter.component.html',
  styleUrl: './dashboard-filter.component.scss'
})
export class DashboardFilterComponent implements OnInit {
  allTags: Tag[];
  allFloors: Floor[];
  filterForm: FormGroup<DashboardFilterForm>;
  @Input({ required: true }) buildingId: number;
  @Input({ required: true }) timeZone: string;
  constructor(private fb: FormBuilder, private stateService: DashboardStateService) {}

  ngOnInit(): void {
    this.allTags = this.stateService.allTags;
    this.allFloors = this.stateService.allFloors;

    this.filterForm = this.fb.group({
      startDate: new FormControl<Date>(TimeUtils.adjustDateToTimezone('', this.timeZone)),
      endDate: new FormControl<Date>(TimeUtils.adjustDateToTimezone(new Date(), this.timeZone)),
      floors: new FormControl<Floor[]>([]),
      tags: new FormControl<Tag[]>([])
    });
    this.filterForm.get('startDate').reset();
    this.filterForm.addValidators(this.validateFilterForm());
  }

  validateFilterForm(): ValidatorFn {
    return (form: FormGroup) => {
      const startDate = form.get('startDate').value;
      const endDate = form.get('endDate').value;
      if (startDate && !endDate) {
        return { endDateRequired: true };
      }
      return null;
    };
  }

  get startDate(): FormControl {
    return this.filterForm.get('startDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.filterForm.get('endDate') as FormControl;
  }

  get floors(): FormControl {
    return this.filterForm.get('floors') as FormControl;
  }

  get tags(): FormControl {
    return this.filterForm.get('tags') as FormControl;
  }

  onFloorRemove(floor: Floor): void {
    const floors = this.floors.value;
    this.floors.setValue(this.removeElement(floors, floor)); // To trigger change detection
  }

  onTagRemove(tag: Tag): void {
    const tags = this.tags.value;
    this.tags.setValue(this.removeElement(tags, tag)); // To trigger change detection
  }

  private removeElement(array: (Floor | Tag)[], toRemove: any): (Floor | Tag)[] {
    return array.filter((e) => e.id !== toRemove.id);
  }

  apply(): void {
    this.stateService.saveFilter(this.filterForm.value);
  }

  reset(): void {
    this.filterForm.reset();
    this.startDate.setValue(TimeUtils.adjustDateToTimezone(new Date(), this.timeZone));
    this.endDate.setValue(TimeUtils.adjustDateToTimezone(new Date(), this.timeZone));
    this.stateService.saveFilter(this.filterForm.value);
  }

  onCloseDatePicker(): void {
    if (this.endDate.value == null || this.endDate.value === '') {
      const today = TimeUtils.adjustDateToTimezone(new Date(), this.timeZone);
      this.endDate.setValue(this.startDate.value > today ? this.startDate.value : today);
    }
  }
}
