import * as h337 from 'heatmap.js';

import { SensorNode } from './SensorNode';
import { SensorNode as NewSensorNode } from 'src/app/shared/models/sensor-node';
import { Tag } from './Tag';
import { SensorNodeStatus } from '../../../angular/modules/layout/or-sensor-node/SensorNodeStatus';
import { SensorNodeAlert } from '../../../angular/modules/layout/or-sensor-node/SensorNodeAlert';
import { StringUtils } from '../../util/StringUtils';
import { BeaconSetting } from './BeaconSetting';
import { LightingConfiguration } from '@angularjs/or/api/building/LightingConfiguration';
import { DuplicateMapping } from '@angularjs/or/api/building/DuplicateMapping';
import { EmDriver } from '@angularjs/or/api/building/EmDriver';
import { LuminaireDriver } from '@angularjs/or/api/building/LuminaireDriver';
import { EmDriverDTO } from '@app/shared/models/em-driver';
import { LuminaireDriverDTO } from '@app/shared/models/luminaire-driver';
import { EmergencyLightingTestState } from '@angularjs/or/data/EmergencyLightingTestState';
import { DISCRIMINATOR } from '@app/shared/models/selectable.interface';

export class FloorplanSensorNode extends SensorNode implements h337.HeatmapDataPoint {
  constructor(
    public id: number,
    public x: number,
    public y: number,
    public tags?: Tag[],
    public tenantIds?: number[],
    public floorId?: number,
    public address?: number,
    public connected?: boolean,
    public installedAt?: Date,
    public bleSeq?: number,
    public bleKey?: number,
    public duplicateAddressMappings?: DuplicateMapping[],
    public duplicateGroupMappings?: DuplicateMapping[],
    public properlyMapped?: boolean,
    public emDrivers?: EmDriver[],
    public luminaireDrivers?: LuminaireDriver[],
    public value: number = 0, // This is for the heatmap
    public hasMappingBeenAttempted?: boolean,
    public radius: number = 0,
    public isHighlighted: boolean = false,
    public isChanged: boolean = false,
    public beaconSetting?: BeaconSetting,
    public lightingConfiguration?: LightingConfiguration,
    // TODO: Remove/Fix it when EM test bug been fixed.
    public isDriverEmergencyLightingTestStarted: boolean = false,
    public isTooManyDriverEmergencyLightingTest: boolean = false,
    public nodeType?: string,
    public groupId?: number,
    public subscriber?: boolean,
    public discriminator?: DISCRIMINATOR,
    public valueSuffix?: string
  ) {
    super(
      id,
      x,
      y,
      tags,
      tenantIds,
      floorId,
      address,
      connected,
      installedAt,
      bleSeq,
      bleKey,
      duplicateAddressMappings,
      duplicateGroupMappings,
      false,
      false,
      beaconSetting,
      lightingConfiguration,
      null,
      null,
      null,
      properlyMapped,
      emDrivers,
      luminaireDrivers
    );
  }

  public static from(other: SensorNode, hasMappingBeenAttempted?: boolean): FloorplanSensorNode {
    const node = new FloorplanSensorNode(
      other.id,
      other.x,
      other.y,
      other.tags,
      other.tenantIds,
      other.floorId,
      other.address,
      other.connected,
      other.installedAt ? new Date(other.installedAt) : null,
      other.bleSeq,
      other.bleKey,
      other.duplicateAddressMappings,
      other.duplicateGroupMappings,
      other.properlyMapped,
      other.emDrivers,
      other.luminaireDrivers
    );

    node.hasMappingBeenAttempted = hasMappingBeenAttempted;
    node.beaconSetting = other.beaconSetting;
    node.lightingConfiguration = other.lightingConfiguration;
    node.lightLevel = other.lightLevel;
    node.presence = other.presence;
    node.scene = other.scene;
    node.nodeType = other.nodeType;
    node.groupId = other.groupId;
    node.subscriber = other.subscriber;
    node.bleScanning = other.bleScanning;

    if (node.lightingConfiguration && node.lightingConfiguration.scene == null && node.scene != null) {
      node.lightingConfiguration.scene = {
        value: node.scene,
        updatedAt: new Date()
      };
    } else if (!node.lightingConfiguration && node.scene) {
      node.lightingConfiguration = new LightingConfiguration(node.id);
      node.lightingConfiguration.scene = {
        value: node.scene,
        updatedAt: new Date()
      };
    }
    return node;
  }

  public static fromNewSensorNodes(nodes: NewSensorNode[]): FloorplanSensorNode[] {
    return nodes.map((n) => {
      return new FloorplanSensorNode(
        n.id,
        n.x,
        n.y,
        n.tags,
        n.tenants ? n.tenants.map((t) => t.id) : [],
        n.floorId,
        n.address,
        n.connected,
        n.installedAt,
        null,
        n.bleKey,
        n.duplicateAddressMappings,
        n.duplicateGroupMappings,
        n.properlyMapped,
        FloorplanSensorNode.fromNewEmDrivers(n.emDrivers),
        FloorplanSensorNode.fromNewLuminaireDrivers(n.luminaireDrivers),
        n.value,
        false,
        n.radius,
        false,
        n.isChanged,
        null,
        null,
        n.isDriverEmergencyLightingTestStarted,
        n.isTooManyDriverEmergencyLightingTest,
        n.nodeType,
        n.groupId,
        n.subscriber,
        n.discriminator
      );
    });
  }

  public static fromNewLuminaireDrivers(luminaires: LuminaireDriverDTO[]): LuminaireDriver[] {
    return luminaires.map((l) => {
      return new LuminaireDriver(
        l.id,
        l.x,
        l.y,
        l.address,
        l.nodeId,
        l.lampTypeId,
        l.burnInHours,
        l.energyConsumption,
        l.conflictingAddress,
        l.daliTypeId,
        l.daliType,
        l.updatedAt,
        l.daliStatus,
        l.scene,
        [],
        true,
        null
      );
    });
  }

  public static fromNewEmDrivers(emDrivers: EmDriverDTO[]): EmDriver[] {
    return emDrivers.map((e) => {
      return new EmDriver(
        e.id,
        e.x,
        e.y,
        e.address,
        e.nodeId,
        e.durationTestResult,
        e.emergencyFailureState,
        e.emergencyMode,
        e.emergencyStatus,
        e.ratedDuration,
        e.conflictingAddress,
        e.updatedAt,
        [],
        true,
        EmergencyLightingTestState.fromNewTestState(e.functionalTestState),
        EmergencyLightingTestState.fromNewTestState(e.durationTestState)
      );
    });
  }

  public get isFaulty(): boolean {
    return (
      this.luminaireDrivers?.some((driver) => driver.daliStates != null && driver.daliStates.length > 0) ||
      this.emDrivers?.some(
        (driver) => driver.emergencyLightingFailureStates != null && driver.emergencyLightingFailureStates.length > 0
      )
    );
  }

  public get isSN3Node(): boolean {
    return SensorNode.isSN3Node(this);
  }
  public get isPassiveNode(): boolean {
    return SensorNode.isPassiveNode(this);
  }

  public get isHIM84Node(): boolean {
    return SensorNode.isHIM84Node(this);
  }

  public get isNodeConnected(): boolean {
    return this.connected;
  }

  public get showDrivers(): boolean {
    return SensorNode.showDrivers(this);
  }

  public get status(): SensorNodeStatus {
    if (!this.properlyMapped) {
      return SensorNodeStatus.UNKNOWN;
    }

    if (!this.isNodeConnected) {
      return SensorNodeStatus.DISCONNECTED;
    }

    if (this.isFaulty) {
      return SensorNodeStatus.FAULTY;
    }

    return SensorNodeStatus.OK;
  }

  public get address16(): string {
    const result = this.properlyMapped ? this.address.toString(16).toUpperCase() : 'Unmapped';
    return result;
  }

  public get beaconEnabled(): boolean {
    if (this.beaconSetting == null || this.beaconSetting.enabled == null) {
      return false;
    } else {
      return this.beaconSetting.enabled;
    }
  }

  public get beaconPowerLevelNum(): number {
    if (this.beaconSetting != null) {
      return this.beaconSetting.powerLevel;
    } else {
      return null;
    }
  }

  public get beaconPowerLevel(): string {
    if (this.beaconSetting == null || this.beaconSetting.powerLevel == null) {
      return 'N/A';
    } else {
      return (this.beaconSetting.powerLevel > 0 ? '+' : '') + this.beaconSetting.powerLevel + ' dBm';
    }
  }

  public get beaconContentStr(): string {
    if (this.beaconSetting != null && this.beaconSetting.content != null) {
      return '0x' + this.beaconSetting.content.toString(16).toUpperCase();
    } else {
      return 'N/A';
    }
  }
  public get beaconUUID(): { name: string; value: string } {
    if (this.beaconSetting == null || this.beaconSetting.uuid == null) {
      return {
        name: 'Not Available',
        value: 'N/A'
      };
    } else {
      return {
        name: this.convertUUIDValueToName(this.beaconSetting.uuid),
        value: this.beaconSetting.uuid
      };
    }
  }

  public get beaconMajor(): string {
    if (this.beaconSetting == null || this.beaconSetting.major == null) {
      return 'N/A';
    } else {
      return this.beaconSetting.major + '';
    }
  }

  public get beaconMinor(): string {
    if (this.beaconSetting == null || this.beaconSetting.minor == null) {
      return 'N/A';
    } else {
      return this.beaconSetting.minor + '';
    }
  }

  public get beaconContent(): { name: string; value: string } {
    if (this.beaconSetting == null || this.beaconSetting.content == null) {
      return {
        name: 'Not Available',
        value: 'N/A'
      };
    } else {
      return {
        name: this.convertContentValueToName(this.beaconSetting.content),
        value: '0x' + this.beaconSetting.content.toString(16).toUpperCase()
      };
    }
  }

  public getBeaconContent(mouseOver: boolean): string {
    return mouseOver ? this.beaconContent.value : this.beaconContent.name;
  }
  public getBeaconUUID(mouseOver: boolean): string {
    return mouseOver ? this.beaconUUID.value : this.beaconUUID.name;
  }
  private convertContentValueToName(value: number): string {
    let name = '0x' + value.toString(16).toUpperCase();
    BeaconSetting.contents.forEach((item) => {
      if (item.value === value) {
        name = item.name;
      }
    });
    return name;
  }
  private convertUUIDValueToName(value: string): string {
    let name = value;
    BeaconSetting.uuids.forEach((item) => {
      if (item.value === value) {
        name = item.name;
      }
    });
    return name;
  }

  public get beaconIntervalStr(): string {
    if (this.beaconSetting != null && this.beaconSetting.beaconInterval != null) {
      return this.convertIntervalValueToLabel(this.beaconSetting.beaconInterval);
    } else {
      return 'N/A';
    }
  }

  public get beaconInterval(): { name: string; value: string } {
    if (this.beaconSetting == null || this.beaconSetting.beaconInterval == null) {
      return {
        name: 'Not Available',
        value: 'N/A'
      };
    } else {
      return {
        name: this.beaconSetting.beaconInterval + ' ms',
        value: this.convertIntervalValueToLabel(this.beaconSetting.beaconInterval)
      };
    }
  }

  public getBeaconInterval(mouseOver: boolean): string {
    return mouseOver ? this.beaconInterval.name : this.beaconInterval.value;
  }

  private convertIntervalValueToLabel(value: number): string {
    let name = '0x' + value.toString(16).toUpperCase();
    BeaconSetting.intervals.forEach((item) => {
      if (item.value === value) {
        name = item.name;
      }
    });
    return name;
  }

  public get beaconUpdateStatus(): string {
    if (this.beaconSetting == null || this.beaconSetting.updateStatus == null) {
      return 'N/A';
    } else {
      return this.beaconSetting.updateStatus;
    }
  }

  public get lightingConfigurationUpdateStatus(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.updateStatus == null) {
      return 'N/A';
    } else {
      return this.lightingConfiguration.updateStatus;
    }
  }

  public get lightingConfigurationZone(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.zone == null) {
      return 'N/A';
    } else {
      return '' + (this.lightingConfiguration.zone !== 15 ? this.lightingConfiguration.zone + 1 : 'X');
    }
  }

  public get lightingConfigurationScene(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.scene == null) {
      return 'N/A';
    } else {
      return '' + this.lightingConfiguration.scene;
    }
  }

  public get lightingConfigurationMaxLight(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.maxLightLevel == null) {
      return 'N/A';
    } else {
      return '' + this.lightingConfiguration.maxLightLevel;
    }
  }

  public get lightingConfigurationMinLight(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.minLightLevel == null) {
      return 'N/A';
    } else {
      return '' + this.lightingConfiguration.minLightLevel;
    }
  }

  public get lightingConfigurationLowLight(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.lowLightLevel == null) {
      return 'N/A';
    } else {
      return '' + this.lightingConfiguration.lowLightLevel;
    }
  }

  public get lightingConfigurationDwellLightTime(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.dwellTime == null) {
      return 'N/A';
    } else {
      return '' + this.lightingConfiguration.dwellTime;
    }
  }

  public get lightingConfigurationLowLightTime(): string {
    if (this.lightingConfiguration == null || this.lightingConfiguration.lowLightTime == null) {
      return 'N/A';
    } else {
      return '' + this.lightingConfiguration.lowLightTime;
    }
  }

  public get alerts(): SensorNodeAlert[] {
    const alerts = [];

    if (!this.connected && this.properlyMapped) {
      alerts.push(new SensorNodeAlert('Sensor node disconnected', null));
    }

    this.luminaireDrivers?.forEach((driver) => {
      if (driver.daliStates != null) {
        driver.daliStates.forEach((state) =>
          alerts.push(
            new SensorNodeAlert('Dali Driver address:' + driver.address16 + ' - ' + StringUtils.humanizeConstant(state))
          )
        );
      }
    });

    this.emDrivers?.forEach((driver) => {
      if (driver.emergencyLightingFailureStates != null) {
        driver.emergencyLightingFailureStates.forEach((state) =>
          alerts.push(
            new SensorNodeAlert('EM Driver address:' + driver.address16 + ' - ' + StringUtils.humanizeConstant(state))
          )
        );
      }
    });

    if (Array.isArray(this.duplicateAddressMappings)) {
      this.duplicateAddressMappings.forEach((dam) => {
        const duplicateAddressMapping = new DuplicateMapping(dam.nodeId, dam.floorId, dam.buildingId, dam.value);
        alerts.push(
          new SensorNodeAlert(
            'Duplicate Mapping Node Id ' + duplicateAddressMapping.nodeId,
            duplicateAddressMapping.getLink()
          )
        );
      });
    }

    if (Array.isArray(this.duplicateGroupMappings)) {
      this.duplicateGroupMappings.forEach((dgm) => {
        const duplicateGroupMapping = new DuplicateMapping(dgm.nodeId, dgm.floorId, dgm.buildingId, dgm.value);
        alerts.push(
          new SensorNodeAlert(
            'Duplicate Grouping (' + duplicateGroupMapping.value + ') Node Id ' + duplicateGroupMapping.nodeId,
            duplicateGroupMapping.getLink()
          )
        );
      });
    }
    return alerts;
  }

  public update(newX: number, newY: number): void {
    this.x = newX;
    this.y = newY;
    this.isChanged = true;
  }

  public reset(newX?: number, newY?: number): void {
    if (newX) {
      this.x = newX;
    }
    if (newY) {
      this.y = newY;
    }
    this.isChanged = false;
  }

  public get getTotalDaliDriver(): number {
    return SensorNode.getTotalDaliDriver(this);
  }

  public get getTotalEMDriver(): number {
    return SensorNode.getTotalEMDriver(this);
  }

  public get hasEmergencyGear(): boolean {
    return SensorNode.getTotalEMDriver(this) > 0 ? true : false;
  }

  public toString(): string {
    return (
      ' id=[' +
      this.id +
      '] ' +
      ' x=[' +
      this.x +
      '] ' +
      ' y=[' +
      this.y +
      '] ' +
      ' address=[' +
      this.address +
      '] ' +
      ' isConnected=[' +
      this.connected +
      '] ' +
      ' mapped=[' +
      this.properlyMapped +
      '] ' +
      ' bleSeq=[' +
      this.bleSeq +
      '] ' +
      ' beaconEnabled=[' +
      this.beaconEnabled +
      '] ' +
      ' beaconPowerLevel=[' +
      this.beaconPowerLevel +
      '] ' +
      ' beaconContent=[' +
      this.beaconContent +
      '] ' +
      ' beaconInterval=[' +
      this.beaconInterval +
      '] ' +
      ' beaconUpdateStatus=[' +
      this.beaconUpdateStatus +
      '] ' +
      ' lightingConfigZone=[' +
      this.lightingConfigurationZone +
      '] '
    );
  }
}
