import { IBuildingService } from '@angularjs/or/services/IBuildingService';
import { DdlSettingService } from '@angularjs/or/services/Ddl/DdlSettingService';
import { DdlModeService } from '@angularjs/or/services/Ddl/DdlModeService';
import { DdlNodeService } from '@angularjs/or/services/Ddl/DdlNodeService';
import { DdlTagService } from '@angularjs/or/services/Ddl/DdlTagService';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';
import { IBuildingRouteService } from '@angularjs/or/angular/routing/IBuildingRouteService';
import {
  DdlMode,
  DdlNode,
  DdlSetting,
  DdlTag
} from '@angularjs/or/api/building/Ddl';
import * as toastr from 'toastr';

export class DDLController {
  public isBusy = true;
  public buildingId: number;
  public disableFloorsMenu = true;
  public tabs: NavigationSection[];
  private setting: DdlSetting;
  private isNew: boolean;
  private ddlMode: DdlMode;
  private ddlNode: DdlNode;
  private ddlTags: DdlTag[];
  private ddlTagId: number | string;
  private address: number;

  constructor(
    private route: IBuildingRouteService,
    private $scope: ng.IScope,
    private ddlSettingService: DdlSettingService,
    private ddlModeService: DdlModeService,
    private ddlNodeService: DdlNodeService,
    private ddlTagService: DdlTagService,
    private buildingService: IBuildingService,
    private navigationService: NavigationService,
    private mysectionInfo: NavigationSectionInfo
  ) {
    this.initializeValues();
    buildingService.getCurrentBuilding().then((building) => {
      this.init();
      this.initNavigation(building);
    });
  }

  private initializeValues(): void {
    this.buildingId = parseInt(this.route.buildingId, 10);
    this.buildingService.setCurrentBuildingId(this.buildingId);
  }

  private init(): void {
    this.isBusy = false;
    this.loadSettng();
    this.getDdlMode();
    this.loadTags();
  }

  private initNavigation(building): void {
    const routeParams: { [p: string]: any } = {};
    if (building != null) {
      routeParams.buildingId = building.id;
    }
    if (building.floors != null && building.floors[0] != null) {
      routeParams.floorId = building.floors[0].id;
    }
    this.navigationService.applyContext(window.location.href, routeParams);
    this.tabs = this.navigationService.getSiblingsFor(this.mysectionInfo.Id);
  }

  private getDdlMode(): void {
    this.isBusy = true;
    this.ddlModeService
      .getDdlMode(this.buildingId)
      .then((response: DdlMode) => {
        if (response) {
          this.ddlMode = response;
        } else {
          this.ddlModeService
            .createDdlMode(this.buildingId)
            .then((mode: DdlMode) => {
              this.ddlMode = mode;
            });
        }
      });
  }

  private updateDdlMode(mode: boolean): void {
    this.ddlModeService
      .updateDdlMode(mode, this.ddlMode.id, this.buildingId)
      .then((response: DdlMode) => {
        this.ddlMode = response;
      });
  }

  private getDdlNode(value: number): void {
    this.isBusy = true;
    if (value) {
      this.ddlNode = new DdlNode(value, this.buildingId);
    }
  }

  private loadSettng(): void {
    this.isBusy = true;
    this.ddlSettingService
      .getDdlSetting(this.buildingId)
      .then((response: DdlSetting) => {
        if (response) {
          this.isNew = false;
          this.setting = response;
          this.address = response.address;
          this.ddlTagId = response.luminaireTagId.toString();

          this.getDdlNode(this.address);
        } else {
          this.isNew = true;
        }
      });
  }

  private loadTags(): void {
    this.isBusy = true;
    this.ddlTagService
      .getDdlTags(this.buildingId)
      .then((response): DdlTag[] => {
        this.ddlTags = response;
        return this.ddlTags;
      });
  }

  public setDdlTag(tag: string): void {
    this.ddlTagId = tag;
  }

  public saveButtonPressed(): void {
    const ddlSetting: DdlSetting = {
      outdoorSensorNodeId: null,
      address: this.address,
      buildingId: this.buildingId,
      luminaireTagId:
        typeof this.ddlTagId === 'string'
          ? parseInt(this.ddlTagId, 10)
          : this.ddlTagId
    };

    if (this.isNew) {
      this.ddlSettingService
        .createDdlSetting(ddlSetting)
        .then((response: DdlSetting) => {
          this.setting = response;
          this.isNew = false;
          this.address = response.address;
          this.ddlTagId = response.luminaireTagId.toString();

          this.getDdlNode(this.address);
          toastr.success('Created ddl setting successfully!');
        });
    } else {
      this.ddlSettingService
        .updateDdlSetting(ddlSetting, this.setting.id)
        .then((response: DdlSetting) => {
          this.setting = response;
          this.isNew = false;
          this.address = response.address;
          this.ddlTagId = response.luminaireTagId.toString();

          this.getDdlNode(this.address);
          toastr.success('Updated ddl setting successfully!');
        });
    }
  }
}
