<nav class="or-site-menu" *ngIf="user && building">
  <ul class="or-inline-items">
    <ng-container *ngFor="let section of menuItems">
      <li class="or-inline-item" *ngIf="isAuthorizedForMenuItem(section)">
        <a
          class="or-button or-site-menu-link"
          [ngClass]="{ 'or-active': section.isActive() }"
          (click)="navigateTo(section)"
        >
          <span
            class="or-icon or-small or-inverse"
            [ngClass]="section.info.IconClass"
            *ngIf="section.info.IconClass"
          ></span>
          <span class="or-button-label" [textContent]="section.info.name"></span
        ></a>
      </li>
    </ng-container>
  </ul>
</nav>
