<div id="orHeader">
  <span class="or-portal-logo" [routerLink]="['/buildings']"></span>
  <app-site-menu *ngIf="siteMenuActive$ | async" [user]="user$ | async" [building]='building$ | async' [menuItems]="menuItems"> </app-site-menu>
  <app-user-menu
    *ngIf="userMenuActive$ | async"
    [user$]="user$"
    [floorsMenuActive$]="floorsMenuActive$"
    [buildingsMenuActive$]="buildingsMenuActive$"
    [sessionMenuActive$]="sessionMenuActive$"
  >
  </app-user-menu>
</div>
