import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { MatAccordion } from '@angular/material/expansion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { UserService } from '@app/shared/services/user/user.service';
import { GatewayService } from '@app/shared/services/gateway.service';
import { Gateway } from '@app/api/building/gateway/Gateway';
import { SensorNodeDTO } from '@app/shared/models/sensor-node';
import { Floor } from '@app/shared/models/floor.interface';
import { SensorNodeService } from '@app/shared/services/sensor-node.service';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { Environment, environmentToken } from '@environment';

const allPacketTypes = [
  'BeaconStatusNotification',
  'BleAcceptanceNotification',
  'BleQueryNotification',
  'ConflictingDeviceNotification',
  'ConnectionStatusNotification',
  'DaliStatusNotifications',
  'DataPointBatch',
  'DdlOutdoorNotification',
  'DeviceLimitExceededNotification',
  'ElmtNotification',
  'EnOceanSwitchNotification',
  'FujitsuSensorNotification',
  'MeshAddressUpdateNotification',
  'MissingDeviceNotification',
  'MissingDeviceResponse',
  'NodeStatusNotification',
  'PhysicalMappingNotification',
  'PubSubNotification',
  'QueryCurveNotification',
  'QueryPicResponseNotification',
  'SceneNotifications',
  'SensorNodeNotification',
  'TunableWhiteNotification',
  'UnknownNotification'
] as const;

type PacketType = (typeof allPacketTypes)[number];

interface PacketHeader {
  packetType: string;
  timestamp: string;
}

type GatewayNotification = {
  '@class': string;
  buildingId: number;
  deviceAddress: number;
  gatewayAddress: string;
  gatewayId: number;
  timestamp: string;
  gateway?: string;
};

interface InboundPacket extends PacketHeader {
  hex: string;
  notification: GatewayNotification;
}

interface DisplayPacket extends Omit<InboundPacket, 'notification'> {
  packetType: PacketType;
  timestamp: string;
}

interface OutboundPacket extends PacketHeader {
  gateway: any;
  gatewayMessages: any;
  body: {
    address: string;
    buildingId: number;
    addressOffset: number;
    versionNumber: number;
    networkType: string;
  };
  messages: {
    node: number;
    command: string;
    tid: number;
  }[];
}

@Component({
  selector: 'app-packet-streamer',
  templateUrl: './packet-streamer.component.html',
  styleUrls: ['./packet-streamer.component.scss']
})
export class GlobalAdministrationPacketStreamerComponent {
  loading$ = new BehaviorSubject(false);

  separatorKeysCodes: number[] = [ENTER, COMMA];

  packetTypeCtrl = new FormControl('');
  buildingIdsCtrl = new FormControl(0);
  gatewayAddressesCtrl = new FormControl(null);
  sensorNodeAddressesCtrl = new FormControl(null);

  filteredPacketTypes$: Observable<string[]>;

  selectedWsEndpoint: string;
  selectedTopic: string;
  packetTypes: string[] = [];
  selectedBuildingId: number;
  selectedFloorId: number;
  selectedGateways: Gateway[] = [];
  selectedSensorNodeAddresses: SensorNodeDTO[] = [];

  allBuildingIdsWithNames$: Observable<{ id: number; name: string }[]>;
  allFloors$: Observable<Floor[]>;
  allGateways: Gateway[] = [];

  @ViewChild('packetTypeInput') packetTypeInput: ElementRef<HTMLInputElement>;
  @ViewChild('buildingIdsInput') buildingIdsInput: ElementRef<HTMLInputElement>;
  @ViewChild('gatewayAddressesInput') gatewayAddressesInput: ElementRef<HTMLInputElement>;
  @ViewChild('sensorNodeAddressesInput') sensorNodeAddressesInput: ElementRef<HTMLInputElement>;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  ingestionWebSocketEndPoint = this.environment.apiUrl + '/ws/ingestion';
  gatewayMessageServiceWebSocketEndPoint = this.environment.apiUrl + '/ws/gateway-message-service';
  inboundTopic = '/topic/inbound';
  outboundTopic = '/topic/outbound';
  stompClient: any;
  packets: string[] = [];
  outboundPackets: OutboundPacket[] = [];
  inboundByPayload: Record<string, DisplayPacket[]> = {};

  constructor(
    private userService: UserService,
    private gatewayService: GatewayService,
    private sensorNodeService: SensorNodeService,
    @Inject(environmentToken) private readonly environment: Environment
  ) {
    this.allBuildingIdsWithNames$ = this.userService.getBuildingIdsWithNames();

    this.filteredPacketTypes$ = this.packetTypeCtrl.valueChanges.pipe(
      startWith(null),
      map((packetType: string | null) => (packetType ? this._filterPacketTypes(packetType) : allPacketTypes.slice()))
    );
  }

  _connect(): void {
    const ws = new SockJS(this.selectedWsEndpoint);
    this.stompClient = Stomp.over(ws);
    const self = this;
    self.stompClient.connect(
      {},
      (_frame) => {
        self.loading$.next(true);
        self.stompClient.subscribe(self.selectedTopic, (packet) => {
          if (self.selectedTopic === '/topic/inbound') {
            self.onInboundMessageReceived(packet);
          } else {
            self.onOutboundMessageReceived(packet);
          }
        });
      },
      (error) => {
        setTimeout(this._connect, 5000);
      }
    );
  }

  _disconnect(): void {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
    this.loading$.next(false);
  }

  _send(message): void {
    this.stompClient.send('/app/hello', {}, JSON.stringify(message));
  }

  onInboundMessageReceived(message: { body: string }): void {
    const inboundMessage = JSON.parse(message.body) as InboundPacket;
    const inboundPacket = {
      ...inboundMessage.notification,
      packetType: inboundMessage.notification['@class'].split('.').pop() as PacketType,
      timestamp: new Date().toLocaleString(),
      hex: inboundMessage.hex
    };
    if (
      (this.packetTypes.length === 0 || this.packetTypes.includes(inboundPacket.packetType)) &&
      (this.selectedBuildingId == null || inboundPacket.buildingId === this.selectedBuildingId) &&
      (this.selectedGateways.length === 0 ||
        this.selectedGateways.map((g) => g.address).includes(inboundPacket.gatewayAddress || inboundPacket.gateway)) &&
      (this.selectedSensorNodeAddresses.length === 0 ||
        this.selectedSensorNodeAddresses.map((s) => s.address).includes(inboundPacket.deviceAddress))
    ) {
      this.inboundByPayload[inboundPacket.hex] = this.inboundByPayload[inboundPacket.hex]
        ? [...this.inboundByPayload[inboundPacket.hex], inboundPacket]
        : [inboundPacket];
    }
  }

  onOutboundMessageReceived(message: { body: string }): void {
    const outboundMessage = JSON.parse(message.body) as OutboundPacket;
    outboundMessage.timestamp = new Date().toLocaleString();
    outboundMessage.packetType = 'GatewayMessage';
    outboundMessage.body = {
      ...outboundMessage.gateway
    };
    outboundMessage.messages = outboundMessage.gatewayMessages;

    if (
      (this.selectedBuildingId == null || outboundMessage.body.buildingId === this.selectedBuildingId) &&
      (this.selectedGateways.length === 0 ||
        this.selectedGateways.map((g) => g.address).includes(outboundMessage.body.address)) &&
      (this.selectedSensorNodeAddresses.length === 0 ||
        this.selectedSensorNodeAddresses
          .map((s) => s.address)
          .some((sn) => outboundMessage.messages.map((m) => m.node).filter((n) => n === sn).length > 0))
    ) {
      this.outboundPackets.push(outboundMessage);
    }
  }

  addPacketType(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.packetTypes.push(value);
    }

    event.chipInput.clear();

    this.packetTypeCtrl.setValue(null);
  }

  addGatewayAddress(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      // this.selectedGateways.push(value);
    }

    event.chipInput.clear();

    this.gatewayAddressesCtrl.setValue(null);
  }

  addSensorNodeAddress(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      // this.sensorNodeAddresses.push(value);
    }

    event.chipInput.clear();

    this.sensorNodeAddressesCtrl.setValue(null);
  }

  removePacketType(packetType: string): void {
    const index = this.packetTypes.indexOf(packetType);

    if (index >= 0) {
      this.packetTypes.splice(index, 1);
    }
  }

  removeGatewayAddress(gateway: Gateway): void {
    const index = this.selectedGateways.indexOf(gateway);

    if (index >= 0) {
      this.selectedGateways.splice(index, 1);
    }
  }

  removeSensorNodeAddress(sensorNode: SensorNodeDTO): void {
    const index = this.selectedSensorNodeAddresses.indexOf(sensorNode);

    if (index >= 0) {
      this.selectedSensorNodeAddresses.splice(index, 1);
    }
  }

  selectedPacketType(event: MatAutocompleteSelectedEvent): void {
    this.packetTypes.push(event.option.viewValue);
    this.packetTypeInput.nativeElement.value = '';
    this.packetTypeCtrl.setValue(null);
  }

  selectBuildingId(event: MatSelectChange): void {
    this.selectedBuildingId = event.source.value;
    this.gatewayService
      .getGateways(this.selectedBuildingId)
      .pipe(map((gateways) => gateways.gateway))
      .subscribe((gateways) => {
        this.allGateways = gateways;
      });
    this.allFloors$ = this.userService.getBuilding(this.selectedBuildingId).pipe(map((building) => building.floors));
  }

  selectFloorId(event: MatSelectChange): void {
    this.selectedFloorId = event.source.value;
    this.sensorNodeService.fetchNodes(this.selectedFloorId, this.selectedBuildingId);
  }

  get allSensorNodes$(): Observable<SensorNodeDTO[]> {
    return this.sensorNodeService
      .getCurrentFloorNodes$()
      .pipe(map((sensorNodes) => sensorNodes.filter((sn) => sn.address != null)));
  }

  selectGatewayAddress(event: MatAutocompleteSelectedEvent): void {
    this.selectedGateways.push(event.option.value);
    this.gatewayAddressesInput.nativeElement.value = '';
    this.gatewayAddressesCtrl.setValue(null);
  }

  selectSensorNodeAddress(event: MatAutocompleteSelectedEvent): void {
    this.selectedSensorNodeAddresses.push(event.option.value);
    this.sensorNodeAddressesInput.nativeElement.value = '';
    this.sensorNodeAddressesCtrl.setValue(null);
  }

  private _filterPacketTypes(value: string): string[] {
    const filterValue = value.toLowerCase();
    return allPacketTypes.filter((packetType) => packetType.toLowerCase().includes(filterValue));
  }

  private _filterBuildingIds(id: number, value: { id: number; name: string }[]): { id: number; name: string }[] {
    return value.filter((buildingIdWithName) => buildingIdWithName.id === id);
  }

  clear(): void {
    this.inboundByPayload = {};
    this.outboundPackets = [];
  }

  start(): void {
    this._connect();
  }

  stop(): void {
    this._disconnect();
  }

  selectedTrafficType(event: MatRadioChange): void {
    this.selectedWsEndpoint =
      event.value === 'inbound' ? this.ingestionWebSocketEndPoint : this.gatewayMessageServiceWebSocketEndPoint;
    this.selectedTopic = event.value === 'inbound' ? this.inboundTopic : this.outboundTopic;
  }
}
