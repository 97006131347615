import { Floor } from './floor.interface';
import { LuminaireManufacturer } from './luminaire-manufacturer.interface';
import { Tag } from './tag.interface';
import { Tenant } from '@app/shared/models/tenant.interface';
import { Gateway } from '@app/api/building/gateway/Gateway';
import { ServiceLevelType } from '@app/buildings/model/service-level-type';

export interface Building {
  id: number;
  name: string;
  address: Address;
  floors: Floor[];
  tags: Tag[];
  tenants: Tenant[];
  timeZone: string;
  thumbnailImage: string;
  realTimeBuildingData?: RealTimeBuildingData;
  luminaireManufacturers?: LuminaireManufacturer[];
  latitude: number;
  longitude: number;
  managingCompany: ManagingCompany;
  statusFrequencySeconds: number;
  gateways?: Gateway[];
  serviceLevel: ServiceLevelType;
  buildingFloorAreaSqMeters: number;
  serviceLevelStartDate?: Date;
}

export function createDummyBuildingForTest(): Building {
  return {
    id: 1,
    name: 'Building 1',
    address: {
      addressLine1: '123 Main St',
      addressLine2: '',
      addressLine3: '',
      postcode: '12345',
      country: 'USA'
    },
    timeZone: 'America/Chicago',
    floors: [],
    tags: [],
    tenants: [],
    thumbnailImage: '',
    latitude: 0,
    longitude: 0,
    managingCompany: {
      id: 1,
      companyName: 'Company 1'
    },
    statusFrequencySeconds: 0,
    serviceLevel: ServiceLevelType.SMART_LIGHT,
    buildingFloorAreaSqMeters: 100
  };
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postcode: string;
  country: string;
}

export interface RealTimeBuildingData {
  nodeCount: number;
  offlineNodeCount: number;
  userCount: number;
  occupancy: number;
  energyConsumption: number;
  state: BuildingWarningState;
}

export enum BuildingWarningState {
  OK,
  MODERATE,
  SEVERE
}

export class ManagingCompany {
  id: number;
  companyName: string;
}

export const mockBuilding: Building = Object.assign(
  {},
  {
    id: 100,
    address: {
      addressLine1: 'Line1',
      addressLine2: 'Line2',
      addressLine3: 'Line3',
      country: 'Australia',
      postcode: '3000'
    },
    name: 'Mock Building',
    floors: [],
    tags: [],
    timeZone: 'Australia/Melbourne',
    thumbnailImage: '',
    realTimeBuildingData: {
      energyConsumption: 0,
      nodeCount: 1,
      offlineNodeCount: 1,
      userCount: 1,
      occupancy: 1,
      state: 0
    },
    latitude: 121,
    longitude: 221,
    luminaireManufacturers: [],
    managingCompany: { id: 1, companyName: 'ORT' },
    statusFrequencySeconds: 300,
    serviceLevel: ServiceLevelType.SMART_LIGHT,
    buildingFloorAreaSqMeters: 100,
    tenants: []
  }
);
