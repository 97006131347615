import { NavigationSection } from './navigation-section';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { User } from '@app/shared/models/user.interface';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { Building } from '@app/shared/models/building.interface';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public buildingId: number;
  public floorId: number;
  private siteMap: NavigationSection;

  constructor(private location: Location) {}

  /**
   * Initialize the navigation section to correctly set the navigation status
   * and building information
   * @param {string} locationUrl - Current location of the view
   * @param {Building} building - current building info
   * @private
   */
  public initNavigation(locationUrl: string, building: Building): void {
    const routeParams: Record<string, any> = {};
    if (building != null) {
      routeParams.buildingId = building.id;
      routeParams.tenants = building.tenants;
    }
    if (building.floors != null && building.floors[0] != null) {
      routeParams.floorId = building.floors[0].id;
      // Keeping the floors in route params to avoid making n/w calls to fetch building floors.
      routeParams.floors = building.floors;
    }
    this.applyContext(locationUrl, routeParams);
  }

  public applyContext(locationUrl: string, routeParams?: object): void {
    this.applyRouteParams(routeParams);
    this.applyLocation(locationUrl);
  }

  public applyLocation(locationUrl: string): void {
    this.siteMap.applyLocation(locationUrl);
  }

  public applyRouteParams(routeParams: object): void {
    this.siteMap.applyRouteParams(routeParams);
  }

  public addRootSection(section: NavigationSection): void {
    if (!section.hasParent()) {
      this.siteMap = section;
    }
  }

  public addSectionToSiteMap(section: NavigationSection): void {
    const routeToSection = NavigationSection.getRouteToSection(section.info.Route);
    this.siteMap.addSubSection(section, routeToSection);
  }

  public addSectionsToSiteMap(navigationSections: NavigationSection[]): void {
    navigationSections.forEach((navigationSection: NavigationSection) => {
      this.addSectionToSiteMap(navigationSection);
    });
  }

  public getRootSection(): NavigationSection {
    return this.siteMap;
  }

  public getActiveSection(): NavigationSection {
    this.applyLocation(this.location.path());
    return this.siteMap.getActiveSection();
  }

  public getSectionById(id: string): NavigationSection {
    const found = this.siteMap.getSubSection(id);
    return found || this.siteMap;
  }

  public getParentFor(id: string): NavigationSection {
    return this.getSectionById(id).getParent();
  }

  public getChildrenFor(id: string): NavigationSection[] {
    return this.getSectionById(id).getChildren();
  }

  public getSiblingsFor(id: string): NavigationSection[] {
    return this.getParentFor(id).getChildren();
  }

  public navigateToSection(section: NavigationSection, buildingId?: number, floorId?: number): void {
    const route = section.getRoute(buildingId ? buildingId : this.buildingId, floorId ? floorId : this.floorId);
    this.location.go(route);
  }

  public navigateToSectionWithQueryString(
    section: NavigationSection,
    buildingId?: number,
    floorId?: number,
    queryParams?: string
  ): void {
    const route = section.getRoute(buildingId ? buildingId : this.buildingId, floorId ? floorId : this.floorId);
    this.location.go(route, queryParams, false);
  }

  public navigateToActiveSection(user: User, buildingId?: number, floorId?: number): void {
    this.applyLocation(this.location.path());
    const section = this.siteMap.getActiveSection() || this.siteMap;
    if (buildingId != null && section.info.IsAuthorized(user, buildingId)) {
      this.navigateToSection(section, buildingId, floorId);
    } else {
      this.navigateToSection(this.getSectionById('buildings'));
    }
  }

  public getSiteMenu(): NavigationSection[] {
    this.getSectionById('global-administration').info.isAuthorized = hasGlobalAuthority(GlobalAuthority.MANAGE_USERS);

    this.getSectionById('administration').info.isAuthorized = hasAnyBuildingAuthority([
      BuildingAuthorityType.MANAGE_USERS,
      BuildingAuthorityType.MANAGE_BUILDING
    ]);

    this.getSectionById('building').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_BUILDING);
    this.getSectionById('floors').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_FLOORS);
    this.getSectionById('tenants').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_FLOORS);
    this.getSectionById('gateways').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_GATEWAYS);
    this.getSectionById('users').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_USERS);
    this.getSectionById('tenants').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_BUILDING);
    this.getSectionById('roles').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_ROLES);

    this.getSectionById('analytics').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.ANALYTICS);
    this.getSectionById('nodes').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_NODES);
    this.getSectionById('schedules').info.isAuthorized = hasBuildingAuthority(BuildingAuthorityType.MANAGE_SCHEDULES);

    this.getSectionById('emergency-lighting').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING
    );
    this.getSectionById('emergency-lighting-schedules').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING
    );
    this.getSectionById('emergency-lighting-logs').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING
    );
    this.getSectionById('emergency-lighting-reports').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING
    );
    this.getSectionById('emergency-lighting-manual-tests').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING
    );

    this.getSectionById('integrations').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_BACNET_AREAS
    );
    this.getSectionById('integrations-areas').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_BACNET_AREAS
    );
    this.getSectionById('beacons').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_BLUETOOTH_BEACONS
    );
    this.getSectionById('lighting-configuration').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_LIGHTING_CONFIGURATION
    );
    this.getSectionById('circadian-curves').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.MANAGE_CIRCADIAN_RHYTHM
    );
    this.getSectionById('map-nodes').info.isAuthorized = hasAuthorityExceptTenants(BuildingAuthorityType.MANAGE_NODES);

    this.getSectionById('dashboard').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS
    );

    this.getSectionById('general-dashboard').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS
    );

    this.getSectionById('energy-dashboard').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS
    );

    this.getSectionById('occupancy-dashboard').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS
    );

    this.getSectionById('maintenance-dashboard').info.isAuthorized = hasBuildingAuthority(
      BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS
    );

    this.getSectionById('new').info.isAuthorized = hasGlobalAuthority(GlobalAuthority.ADD_BUILDINGS);

    const sections = this.getSectionById('buildings').getChildren();
    const navigationSections: NavigationSection[] = [];
    sections.forEach((section) => {
      if (section.info.IconClass) {
        navigationSections.push(section);
      }
    });
    return navigationSections;
  }
}

function hasBuildingAuthority(authority: BuildingAuthorityType): (u: User, id: number) => boolean {
  return (user: User, buildingId: number) => {
    return user.buildingAuthorizations.some((ba) => {
      if (ba.buildingId === buildingId) {
        return ba.authorities.some((a) => a.toString() === authority.toString());
      }
      return false;
    });
  };
}

/*
 *  This function is used to check if the user has the authority to access the section
 *  if the user is a tenant user, then the user should not have any building authorizations
 * */
function hasAuthorityExceptTenants(authority: BuildingAuthorityType): (u: User, id: number) => boolean {
  return (user: User, buildingId: number) => {
    if (user?.tenantIds?.length > 0) {
      return false;
    }
    return user.buildingAuthorizations.some((ba) => {
      if (ba.buildingId === buildingId) {
        return ba.authorities.some((a) => a.toString() === authority.toString());
      }
      return false;
    });
  };
}

function hasAnyBuildingAuthority(authority: BuildingAuthorityType[]): (u: User, id: number) => boolean {
  return (user: User, buildingId: number) => {
    return user.buildingAuthorizations.some((ba) => {
      if (ba.buildingId === buildingId) {
        return ba.authorities.some((a) => authority.filter((authType) => authType.toString() === a.toString()));
      }
      return false;
    });
  };
}

function hasGlobalAuthority(authority: GlobalAuthority): (u: User) => boolean {
  return (user: User) => {
    return user.globalAuthorities.some((ba) => {
      return ba === authority;
    });
  };
}
