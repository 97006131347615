import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, delay, Observable, of, Subject, switchMap } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { takeUntil } from 'rxjs/operators';
import { WidgetItem } from '@app/dashboard/model/widget-item';
import { RefreshPageService } from '@app/shared/services/refresh-page/refresh-page.service';
import { NumericUtils } from '@app/shared/utils/numeric';

@Component({
  selector: 'app-count-widget',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './count-widget.component.html',
  styleUrl: './count-widget.component.scss'
})
export class CountWidgetComponent implements OnInit, OnDestroy {
  @Input({ required: true }) item: WidgetItem;
  @Input({ required: true }) refreshEvent: EventEmitter<WidgetItem>;
  body: string;
  isLoadingSource = new BehaviorSubject(true);
  private destroy$ = new Subject<void>();

  constructor(private refreshPageService: RefreshPageService) {}

  ngOnInit(): void {
    this.item
      .getData()
      .pipe(takeUntil(this.destroy$), delay(700))
      .subscribe((data: any) => {
        this.body = NumericUtils.fixDigitsInValue(data);
        this.isLoadingSource.next(false);
      });

    this.refreshEvent
      .pipe(
        takeUntil(this.destroy$),
        switchMap((widgetItem: WidgetItem) => {
          if (!widgetItem || widgetItem.id === this.item.id) {
            this.isLoadingSource.next(true);
            return this.item.getData();
          }
          return of();
        })
      )
      .subscribe((data: any) => {
        this.body = NumericUtils.fixDigitsInValue(data);
        this.isLoadingSource.next(false);
      });

    this.refreshPageService.pageRefresh$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.isLoadingSource.next(true);
      this.item
        .getData()
        .pipe(delay(700))
        .subscribe((data) => {
          this.isLoadingSource.next(false);
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSource.asObservable();
  }
}
