import { GridsterItem } from 'angular-gridster2';
import { Observable } from 'rxjs';
import { ChartType } from '@app/dashboard/widgets/chart-widget/chart-widget.component';
import { DateInterval, GroupBy, Unit } from '@app/dashboard/model/influx-data';

export interface ChartData {
  key?: number;
  name?: string | number;
  value: number;
  time?: Date | number;
  // for tooltip
  extra?: Record<string, string>;
}

export interface SeriesChartData {
  key?: number;
  name?: string | number;
  series: ChartData[];
  meta?: Record<string, string>;
}

export interface WidgetItem extends GridsterItem {
  id: number;
  getData: () => Observable<any>;
  title: string;
  subTitle?: string;
  footer?: string;
  helpText?: string;
  type: WidgetType;
  chartType?: ChartType;
  iconClass?: string;
  hideIcon?: boolean;
  dataCy?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
}

export interface WidgetResult {
  groupBy: GroupBy;
  dateInterval: DateInterval;
  chartData: ChartData[];
  seriesChartData: SeriesChartData[];
  singleValue: number;
  unit: Unit;
}

export interface WidgetInfo {
  title: string;
  helpText: string;
  dataCy: string;
}

export type WidgetType = 'count' | 'chart' | 'count-compared';

export type WidgetKey =
  | 'CURRENT_ENERGY_CONSUMPTION'
  | 'AVERAGE_ENERGY_CONSUMPTION'
  | 'ENERGY_CONSUMPTION_BY_TAGS'
  | 'TOTAL_ENERGY_CONSUMPTION'
  | 'MOST_OCCUPIED_TAGS'
  | 'LEAST_OCCUPIED_TAGS'
  | 'TAG_OCCUPANCY'
  | 'CO2_PRODUCED'
  | 'ENERGY_ESTIMATION'
  | 'ENERGY_SAVING'
  | 'AVG_TAG_OCCUPANCY_DAILY'
  | 'TOTAL_NODES'
  | 'NODES_WITH_FAULTS'
  | 'UNRESPONSIVE_NODES'
  | 'OVERALL_SYSTEM_HEALTH'
  | 'ENERGY_INTENSITY';

export const WidgetUrlConfig: Record<WidgetKey, { url: (buildingId: number) => string }> = {
  CURRENT_ENERGY_CONSUMPTION: {
    url: (buildingId: number) => `/dashboard/${buildingId}/energy-stats/current-energy-consumption`
  },
  AVERAGE_ENERGY_CONSUMPTION: {
    url: (buildingId: number) => `/dashboard/${buildingId}/energy-stats/average-consumption`
  },
  ENERGY_CONSUMPTION_BY_TAGS: {
    url: (buildingId: number) => `/dashboard/${buildingId}/energy-stats/energy-consumption-by-tags`
  },
  TOTAL_ENERGY_CONSUMPTION: {
    url: (buildingId: number) => `/dashboard/${buildingId}/energy-stats/compare-energy-consumption`
  },
  CO2_PRODUCED: {
    url: (buildingId: number) => `/dashboard/${buildingId}/energy-stats/co2-produced`
  },
  ENERGY_SAVING: {
    url: (buildingId: number) => `/dashboard/${buildingId}/energy-stats/energy-saved`
  },
  ENERGY_ESTIMATION: {
    url: (buildingId: number) => `/dashboard/${buildingId}/energy-stats/energy-estimation`
  },
  ENERGY_INTENSITY: {
    url: (buildingId: number) => `/dashboard/${buildingId}/energy-stats/current-energy-intensity`
  },
  MOST_OCCUPIED_TAGS: {
    url: (buildingId: number) => `/dashboard/${buildingId}/occupancy/most-occupied-tags`
  },
  LEAST_OCCUPIED_TAGS: {
    url: (buildingId: number) => `/dashboard/${buildingId}/occupancy/least-occupied-tags`
  },
  TAG_OCCUPANCY: {
    url: (buildingId: number) => `/dashboard/${buildingId}/occupancy/tag-occupancy`
  },
  AVG_TAG_OCCUPANCY_DAILY: {
    url: (buildingId: number) => `/dashboard/${buildingId}/occupancy/average-tag-occupancy-daily`
  },
  TOTAL_NODES: {
    url: (buildingId: number) => `/dashboard/${buildingId}/maintenance/total-nodes`
  },
  NODES_WITH_FAULTS: {
    url: (buildingId: number) => `/dashboard/${buildingId}/maintenance/nodes-count-with-driver-faults`
  },
  UNRESPONSIVE_NODES: {
    url: (buildingId: number) => `/dashboard/${buildingId}/maintenance/unresponsive-nodes-count`
  },
  OVERALL_SYSTEM_HEALTH: {
    url: (buildingId: number) => `/dashboard/${buildingId}/maintenance/overall-system-health`
  }
};

export const MAX_CHART_DATA_ITEM = 5;
export const DEFAULT_CHART_DATA_ITEM = 10;

export type Utilization = 'MOST' | 'LEAST';

export const WIDGET_TEXT: Record<string, WidgetInfo> = {
  MOST_OCCUPIED_TAGS: {
    title: 'Most Occupied Tags',
    dataCy: 'most-occupied-tags',
    helpText:
      'This widget represents most occupied 5 tags in a building till date, if no filters applied (based on occupancy %).'
  },
  LEAST_OCCUPIED_TAGS: {
    title: 'Least Occupied Tags',
    dataCy: 'least-occupied-tags',
    helpText:
      'This widget represents least occupied 5 tags in a building till date, if no filters applied (based on occupancy %).'
  },
  AVERAGE_ENERGY_CONSUMPTION: {
    title: 'Average Energy Consumption per Tag',
    dataCy: 'average-energy-consumption-per-tag',
    helpText:
      'This widget represents the average energy consumed per tag in a building till date, if no filters applied. Filters applicable are Date Range, Floors and Tags.'
  },
  ENERGY_CONSUMPTION_BY_TAGS: {
    title: 'Energy Consumption per Tag',
    dataCy: 'energy-consumption-per-tag',
    helpText:
      'This widget represents tags with most energy consumption in a building till date, if no filters applied. Filters applicable are Date Range, Floors and Tags.'
  },
  CO2_PRODUCED: {
    title: 'CO<sub>2</sub> Produced',
    dataCy: 'co2-produced',
    helpText:
      'Total CO2 produced by the building since commencement. This value depends on the current energy consumption value. No filters will be applied.'
  },
  CURRENT_ENERGY_CONSUMPTION: {
    title: 'Energy Consumption',
    dataCy: 'current-energy-consumption',
    helpText: 'Total energy consumption of the building since commencement. No filters will be applied.'
  },
  ENERGY_SAVING: {
    title: 'Energy Saving',
    dataCy: 'energy-saving',
    helpText:
      'The total energy saved by the building is calculated based on the difference between the actual energy output ' +
      'and the energy output of 40W lights at full capacity. No filters are applied in the General tab, while only' +
      ' the date filter is used in the Energy tab.'
  },
  ENERGY_ESTIMATION: {
    title: 'Energy Estimation',
    dataCy: 'energy-estimation',
    helpText:
      'The energy estimated for the current year based the total energy consumed last year as well as the energy consumed last year to the same day.'
  },
  AVG_TAG_OCCUPANCY: {
    title: 'Average Occupancy Rate per Tag',
    dataCy: 'average-occupancy-rate-per-tag',
    helpText:
      'Represents the average occupancy per tag in a given time period. Filters applicable are Date Range and Tags.'
  },
  AVG_TAG_OCCUPANCY_PER_DAILY: {
    title: 'Average Tag Occupancy per Day (%)',
    dataCy: 'average-occupancy-per-day',
    helpText:
      'Represents the average occupancy per tag per day for a given time period. Filters applicable are Date Range and Tags.'
  },
  TOTAL_ENERGY_CONSUMPTION: {
    title: 'Total Energy Consumption',
    dataCy: 'total-energy-consumption-comparison',
    helpText:
      'Total energy consumption of the building over the selected time period from the filter compared with one year before same period of time. If user selects a period more than a year, start date will be adjusted to 1 year before the end date.'
  },
  TOTAL_ANNUAL_ENERGY_COMPARISON: {
    title: 'Energy Consumption Last Year vs Current Year',
    dataCy: 'energy-consumption-last-year-vs-current-year',
    helpText:
      'This widget represents the energy consumption of the building for the last year compared to the current year. No filters are applied.'
  },
  TOTAL_NODES: {
    title: 'Total Number of Nodes',
    dataCy: 'total-number-of-nodes',
    helpText: 'The total number of nodes(mapped and unmapped) in a building. No filters will be applied'
  },
  ENERGY_INTENSITY: {
    title: 'Energy Intensity',
    dataCy: 'energy-intensity',
    helpText:
      'Energy intensity is calculated based on the square meters covered by the smart lighting system. This may differ from the building`s ' +
      'total square meter area, depending on the specified coverage.' +
      'If N/A is displayed, please ensure that the square meter coverage is specified for the building.'
  },
  NODES_WITH_FAULTS: {
    title: 'Nodes Requiring Maintenance',
    dataCy: 'nodes-with-faults',
    helpText:
      'This widget represents the number of luminaires that require maintenance in a building. No filters are applied.'
  },
  UNRESPONSIVE_NODES: {
    title: 'Unresponsive Nodes',
    dataCy: 'unresponsive-nodes',
    helpText: 'This widget represents the number of unresponsive nodes in a building. No filters are applied.'
  },
  OVERALL_SYSTEM_HEALTH: {
    title: 'Overall System Health',
    dataCy: 'overall-system-health',
    helpText: 'This represents the percentage of healthy nodes out of total nodes. No filters are applied.'
  }
};
