import * as angular from 'angular'; // Automatically added
import { IUserService } from '@angularjs/or/services/IUserService';
import { User } from '@angularjs/or/api/auth/User';
import * as toastr from 'toastr';

export class UserController {
  public disableFloorsMenu = true;
  public disableBuildingsMenu = true;
  public disableSiteMenu = true;
  private initialUserDetails: User;
  public userDetails: User;
  public isFormBusy = false;

  constructor(private userService: IUserService) {
    this.userService.getCurrentUser().then((user) => this.init(user));
  }

  private init(user: User) {
    this.userDetails = user;
    this.initialUserDetails = angular.copy(user);
  }

  public onSubmit(callback: () => void, target: any) {
    this.isFormBusy = true;
    this.userService
      .updateUser(this.userDetails)
      .then(() => {
        this.userService.getCurrentUser().then((user) => {
          this.init(user);
          callback.bind(target)();
          toastr.success(
            'Your user information was successfully saved.',
            'Success',
            {
              positionClass: 'toast-bottom-right'
            }
          );
          this.isFormBusy = false;
        });
      })
      .catch((error: number) => {
        toastr.error('Your user information could not be updated.', 'Error', {
          positionClass: 'toast-bottom-right'
        });
      });
  }

  public onReset() {
    this.isFormBusy = true;
    angular.copy(this.initialUserDetails, this.userDetails);
    this.isFormBusy = false;
  }
}
