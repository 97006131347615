import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Environment, environmentToken } from '@environment';

@Injectable({
  providedIn: 'root'
})
/*
  this service can be used either as a directive to remove elements
  or via the service directly.

  ng+ Directive: isFeatureAvailable="Key of Feature in string"
  AngularJS Directive: is-feature-available="Key of Feature in string" (Is part of the IsAuthorized Directive)
 */
export class FeatureService {
  private readonly features: object;

  constructor(@Inject(environmentToken) private readonly environment: Environment) {
    this.features = environment.features;
  }

  isAvailable(key: string): Observable<boolean> {
    // show/hide features only based on their presence in the "features" object
    if (!this.features.hasOwnProperty(key)) {
      return of(true);
    }
    return of(this.features[key] === true);
  }

  private addFeature(key: string): void {
    this.features[key] = true;
  }
}
