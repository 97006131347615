import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  ChartData,
  DEFAULT_CHART_DATA_ITEM,
  MAX_CHART_DATA_ITEM,
  SeriesChartData,
  Utilization,
  WidgetUrlConfig
} from '@app/dashboard/model/widget-item';
import { catchError, map } from 'rxjs/operators';
import { WidgetResource } from '@app/shared/resources/widget.resource';
import {
  AggregationType,
  getDefaultInfluxQueryOutlineForBuilding,
  InfluxQueryOutline
} from '@app/dashboard/model/influx-data';
import { DashboardStateService } from '@app/dashboard/dashboard-filter/dashboard-state.service';
import { TimeUtils } from '@app/shared/utils/time.utils';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  constructor(private widgetResource: WidgetResource, private dashboardStateService: DashboardStateService) {}

  getMostUtilizedRooms(): Observable<any> {
    let data = [
      {
        name: 'Workplace 2',
        value: 90
      },
      {
        name: 'Meeting room 3',
        value: 70
      },
      {
        name: 'Meeting room 2',
        value: 80
      },
      {
        name: 'Workplace 1',
        value: 50
      },
      {
        name: 'Phone room',
        value: 35
      }
    ];
    data = data.map((item) => ({ ...item, extra: { unit: '%' } }));
    return of(data);
  }

  getEnergyConsumptionForBuilding(aggregationType?: AggregationType): Observable<ChartData[]> {
    const url = (
      aggregationType === 'AVERAGE'
        ? WidgetUrlConfig.AVERAGE_ENERGY_CONSUMPTION
        : WidgetUrlConfig.ENERGY_CONSUMPTION_BY_TAGS
    ).url(this.dashboardStateService.currentBuilding.id);
    const state = this.dashboardStateService.currentFilterState;
    const defaultPayload = getDefaultInfluxQueryOutlineForBuilding(this.dashboardStateService.currentBuilding);
    const payload: InfluxQueryOutline = {
      ...defaultPayload,
      aggregationType,
      start: state?.startDate?.getTime() ?? defaultPayload.start,
      end: state?.endDate?.getTime() ?? defaultPayload.end,
      floorIds: state?.floors?.map((floor) => floor.id) ?? defaultPayload.floorIds,
      tagIds: state?.tags?.map((tag) => tag.id) ?? defaultPayload.tagIds
    };
    return this.widgetResource.postRequest(url, payload).pipe(
      map((res) =>
        (aggregationType === 'SUM' ? res.chartData.slice(0, MAX_CHART_DATA_ITEM) : res.chartData).map((dataPoint) => ({
          name: this.dashboardStateService.tagIdToTagMap.get(dataPoint.key)?.name || dataPoint.key,
          value: dataPoint.value,
          extra: { unit: res.unit.suffix }
        }))
      )
    );
  }

  getTotalEnergyConsumption(): Observable<SeriesChartData[]> {
    const url = WidgetUrlConfig.TOTAL_ENERGY_CONSUMPTION.url(this.dashboardStateService.currentBuilding.id);
    const state = this.dashboardStateService.currentFilterState;
    const defaultPayload = getDefaultInfluxQueryOutlineForBuilding(this.dashboardStateService.currentBuilding);
    const payload: InfluxQueryOutline = {
      ...defaultPayload,
      aggregationType: 'SUM',
      start: state?.startDate
        ? TimeUtils.toBrowserTimeZone(state.startDate, defaultPayload.zoneId) * 1000
        : defaultPayload.start,
      end: state?.endDate
        ? TimeUtils.toBrowserTimeZone(state.endDate, defaultPayload.zoneId) * 1000
        : defaultPayload.end,
      floorIds: state?.floors?.map((floor) => floor.id) ?? defaultPayload.floorIds,
      tagIds: state?.tags?.map((tag) => tag.id) ?? defaultPayload.tagIds,
      groupBy: 'BUILDING'
    };
    return this.widgetResource.postRequest(url, payload).pipe(
      map((res) =>
        res.seriesChartData.map((dataPoint) => ({
          name: dataPoint.name,
          series: dataPoint.series?.map((data) => ({
            name: data.name,
            value: data.value,
            extra: { unit: res.unit.suffix }
          })),
          meta: dataPoint.meta
        }))
      )
    );
  }

  getTagsOccupancy(utilization?: Utilization): Observable<ChartData[]> {
    const building = this.dashboardStateService.currentBuilding;
    const state = this.dashboardStateService.currentFilterState;
    const url = this.getURL(utilization).url(building.id);
    const defaultPayload = getDefaultInfluxQueryOutlineForBuilding(building);
    const payload: InfluxQueryOutline = {
      ...defaultPayload,
      type: 'MOTION',
      start: state?.startDate?.getTime() ?? defaultPayload.start,
      end: state?.endDate?.getTime() ?? defaultPayload.end,
      floorIds: state?.floors?.map((floor) => floor.id) ?? defaultPayload.floorIds,
      tagIds: state?.tags?.map((tag) => tag.id) ?? defaultPayload.tagIds
    };

    return this.widgetResource.postRequest(url, payload).pipe(
      map((res) =>
        this.getFilteredChartData(res.chartData, utilization, state?.tags?.length).map((dataPoint) => ({
          name: this.dashboardStateService.tagIdToTagMap.get(dataPoint.key)?.name || dataPoint.key,
          value: dataPoint.value,
          extra: { unit: '%' }
        }))
      )
    );
  }

  getCurrentEnergyConsumption(): Observable<string> {
    const url = WidgetUrlConfig.CURRENT_ENERGY_CONSUMPTION.url(this.dashboardStateService.currentBuilding.id);
    return this.widgetResource.getRequest(url).pipe(map((res) => `${res.singleValue} kWh`));
  }

  getAvgTagOccupancyPerDay(): Observable<SeriesChartData[]> {
    const building = this.dashboardStateService.currentBuilding;
    const state = this.dashboardStateService.currentFilterState;
    const url = WidgetUrlConfig.AVG_TAG_OCCUPANCY_DAILY.url(building.id);
    const defaultPayload = getDefaultInfluxQueryOutlineForBuilding(building);
    const payload: InfluxQueryOutline = {
      ...defaultPayload,
      dateInterval: 'DAILY',
      type: 'MOTION',
      start: state?.startDate?.getTime() ?? defaultPayload.start,
      end: state?.endDate?.getTime() ?? defaultPayload.end,
      floorIds: state?.floors?.map((floor) => floor.id) ?? defaultPayload.floorIds,
      tagIds: state?.tags?.map((tag) => tag.id) ?? defaultPayload.tagIds
    };
    return this.widgetResource.postRequest(url, payload).pipe(
      map((res) =>
        res.seriesChartData.map((dataPoint) => {
          const tag = this.dashboardStateService.tagIdToTagMap.get(dataPoint.key);
          const tagColor = tag?.color;
          return {
            name: tag?.name || dataPoint.key,
            series: dataPoint.series?.map((data) => ({
              name: data.name,
              value: Number(data.value.toFixed(5))
            })),
            meta: { color: tagColor || '000' }
          };
        })
      )
    );
  }

  getCo2Produced(): Observable<string> {
    const url = WidgetUrlConfig.CO2_PRODUCED.url(this.dashboardStateService.currentBuilding.id);
    return this.widgetResource.getRequest(url).pipe(map((res) => `${res.singleValue} kg`));
  }

  getEnergySaved(shouldApplyDate: boolean): Observable<string> {
    const building = this.dashboardStateService.currentBuilding;
    const state = this.dashboardStateService.currentFilterState;

    const defaultPayload = getDefaultInfluxQueryOutlineForBuilding(building);
    const startTime = shouldApplyDate && state?.startDate != null ? state.startDate.getTime() : defaultPayload.start;
    const endTime = shouldApplyDate && state?.endDate != null ? state.endDate.getTime() : defaultPayload.end;
    const payload: InfluxQueryOutline = {
      ...defaultPayload,
      type: 'LIGHT_OUTPUT_LEVEL',
      aggregationType: 'ENERGY_SAVING',
      start: startTime,
      end: endTime
    };

    const url = WidgetUrlConfig.ENERGY_SAVING.url(building.id);

    return this.widgetResource.postRequest(url, payload).pipe(map((res) => `${res.singleValue / 1000} kWh`));
  }

  getBuildingConsumptionComparison(): Observable<{ lastYear: string; currentYear: string }> {
    const building = this.dashboardStateService.currentBuilding;
    const defaultPayload = getDefaultInfluxQueryOutlineForBuilding(building);
    const currentYear = new Date().getFullYear();
    const payload: InfluxQueryOutline = {
      ...defaultPayload,
      start: new Date(currentYear, 0, 1).getTime(),
      end: new Date(currentYear, 11, 31).getTime(),
      type: 'ENERGY_CONSUMPTION',
      aggregationType: 'SUM',
      dateInterval: 'YEARLY',
      groupBy: 'BUILDING',
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    const url = WidgetUrlConfig.TOTAL_ENERGY_CONSUMPTION.url(building.id);
    return this.widgetResource.postRequest(url, payload).pipe(
      map((res) => {
        const seriesData = res.seriesChartData.flatMap((data) => data.series);
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        const processedData = seriesData.reduce(
          (acc, data) => {
            if (data.name === currentYear.toString()) {
              acc.currentYear += data.value;
            } else if (data.name === lastYear.toString()) {
              acc.lastYear += data.value;
            }
            return acc;
          },
          { currentYear: 0, lastYear: 0 }
        );
        return {
          currentYear: processedData.currentYear + ' kWh',
          lastYear: processedData.lastYear + ' kWh'
        };
      })
    );
  }

  getTotalNodes(): Observable<number> {
    const url = WidgetUrlConfig.TOTAL_NODES.url(this.dashboardStateService.currentBuilding.id);
    return this.widgetResource.getRequest(url).pipe(map((res) => res.singleValue));
  }

  getUnresponsiveNodes(): Observable<number> {
    const url = WidgetUrlConfig.UNRESPONSIVE_NODES.url(this.dashboardStateService.currentBuilding.id);
    return this.widgetResource.getRequest(url).pipe(map((res) => res.singleValue));
  }

  getOverallSystemHealth(): Observable<ChartData[]> {
    const url = WidgetUrlConfig.OVERALL_SYSTEM_HEALTH.url(this.dashboardStateService.currentBuilding.id);
    return this.widgetResource.getRequest(url).pipe(
      map((res) => [
        {
          name: '',
          value: res.singleValue
        }
      ])
    );
  }

  getEnergyYearlyEstimation(): Observable<string> {
    const building = this.dashboardStateService.currentBuilding;
    const defaultPayload = getDefaultInfluxQueryOutlineForBuilding(building);
    const payload: InfluxQueryOutline = {
      ...defaultPayload,
      type: 'ENERGY_CONSUMPTION',
      aggregationType: 'SUM',
      dateInterval: 'YEARLY',
      groupBy: 'BUILDING'
    };

    const url = WidgetUrlConfig.ENERGY_ESTIMATION.url(building.id);
    return this.widgetResource
      .postRequest(url, payload)
      .pipe(map((res) => (res.singleValue === -1 ? 'N/A' : `${res.singleValue / 1000} kWh`)));
  }

  getEnergyIntensity(): Observable<string> {
    const url = WidgetUrlConfig.ENERGY_INTENSITY.url(this.dashboardStateService.currentBuilding.id);
    return (
      this.widgetResource
        .getRequest(url)
        // Comparing with -1 as this value is returned when service have insufficient data for calculation
        .pipe(map((res) => (res.singleValue === -1 ? 'N/A' : `${res.singleValue} kWh/sqm`)))
    );
  }

  getNodesRequiringMaintenance(): Observable<number> {
    const url = WidgetUrlConfig.NODES_WITH_FAULTS.url(this.dashboardStateService.currentBuilding.id);
    return this.widgetResource.getRequest(url).pipe(map((res) => res.singleValue));
  }

  getFilteredChartData = (chartData: ChartData[], utilization?: string, size?: number) => {
    if (utilization === 'MOST') {
      return chartData.slice(0, MAX_CHART_DATA_ITEM);
    } else if (utilization === 'LEAST') {
      return chartData.slice(-MAX_CHART_DATA_ITEM);
    } else {
      return chartData.slice(0, size && size !== 0 ? size : DEFAULT_CHART_DATA_ITEM);
    }
  };

  getURL(utilization?: string): { url: (buildingId: number) => string } {
    if (utilization === 'MOST') {
      return WidgetUrlConfig.MOST_OCCUPIED_TAGS;
    } else if (utilization === 'LEAST') {
      return WidgetUrlConfig.LEAST_OCCUPIED_TAGS;
    } else {
      return WidgetUrlConfig.TAG_OCCUPANCY;
    }
  }
}
