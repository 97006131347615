export class BuildingAuthorityType {
  static ALL = new BuildingAuthorityType('ALL', 'All');
  static VIEW = new BuildingAuthorityType('VIEW', 'View');
  static CONTROL_LIGHTS = new BuildingAuthorityType('CONTROL_LIGHTS', 'Control Lights');
  static ANALYTICS = new BuildingAuthorityType('ANALYTICS', 'Analytics');
  static HIGH_RESOLUTION_ANALYTICS = new BuildingAuthorityType(
    'HIGH_RESOLUTION_ANALYTICS',
    'High Resolution Analytics'
  );
  static MANAGE_CIRCADIAN_RHYTHM = new BuildingAuthorityType('MANAGE_CIRCADIAN_RHYTHM', 'Circadian Curves');
  static MANAGE_NODES = new BuildingAuthorityType('MANAGE_NODES', 'Nodes');
  static MANAGE_FLOORS = new BuildingAuthorityType('MANAGE_FLOORS', 'Floors');
  static MANAGE_GATEWAYS = new BuildingAuthorityType('MANAGE_GATEWAYS', 'Gateways');
  static MANAGE_BACNET_AREAS = new BuildingAuthorityType('MANAGE_BACNET_AREAS', 'BACnet Areas');
  static MANAGE_BLUETOOTH_BEACONS = new BuildingAuthorityType('MANAGE_BLUETOOTH_BEACONS', 'Bluetooth Beacons');
  static MANAGE_LIGHTING_CONFIGURATION = new BuildingAuthorityType(
    'MANAGE_LIGHTING_CONFIGURATION',
    'Lighting Configuration'
  );
  static MANAGE_TAGS = new BuildingAuthorityType('MANAGE_TAGS', 'Tags');
  static MANAGE_USERS = new BuildingAuthorityType('MANAGE_USERS', 'Users');
  static MANAGE_TENANT = new BuildingAuthorityType('MANAGE_TENANT', 'Manage Tenants');
  static MANAGE_ROLES = new BuildingAuthorityType('MANAGE_ROLES', 'Roles');
  static MANAGE_BUILDING = new BuildingAuthorityType('MANAGE_BUILDING', 'Building');
  static MANAGE_SCHEDULES = new BuildingAuthorityType('MANAGE_SCHEDULES', 'Schedules');
  static MANAGE_EMERGENCY_LIGHTING = new BuildingAuthorityType('MANAGE_EMERGENCY_LIGHTING', 'Emergency Lighting');
  static MAINTENANCE = new BuildingAuthorityType('MAINTENANCE', 'Maintenance');
  static MANAGE_MAINTENANCE_UPDATES = new BuildingAuthorityType('MANAGE_MAINTENANCE_UPDATES', 'Maintenance Updates');
  static ALL_TOKEN = new BuildingAuthorityType('ALL_TOKEN', 'All token');
  static UNKNOWN = new BuildingAuthorityType('UNKNOWN', 'Unknown');
  static MANAGE_DDL = new BuildingAuthorityType('MANAGE_DDL', 'Manage DDL');
  static VIEW_DASHBOARD_INSIGHTS = new BuildingAuthorityType('VIEW_DASHBOARD_INSIGHTS', 'View Dashboard Insights');

  static fromName(name: BuildingAuthorityName_): BuildingAuthorityType {
    switch (name) {
      case 'All':
        return BuildingAuthorityType.ALL;
      case 'Building':
        return BuildingAuthorityType.MANAGE_BUILDING;
      case 'View':
        return BuildingAuthorityType.VIEW;
      case 'Control Lights':
        return BuildingAuthorityType.CONTROL_LIGHTS;
      case 'Analytics':
        return BuildingAuthorityType.ANALYTICS;
      case 'High Resolution Analytics':
        return BuildingAuthorityType.HIGH_RESOLUTION_ANALYTICS;
      case 'Circadian Curves':
        return BuildingAuthorityType.MANAGE_CIRCADIAN_RHYTHM;
      case 'Nodes':
        return BuildingAuthorityType.MANAGE_NODES;
      case 'Floors':
        return BuildingAuthorityType.MANAGE_FLOORS;
      case 'Gateways':
        return BuildingAuthorityType.MANAGE_GATEWAYS;
      case 'BACnet Areas':
        return BuildingAuthorityType.MANAGE_BACNET_AREAS;
      case 'Bluetooth Beacons':
        return BuildingAuthorityType.MANAGE_BLUETOOTH_BEACONS;
      case 'Lighting Configuration':
        return BuildingAuthorityType.MANAGE_LIGHTING_CONFIGURATION;
      case 'Tags':
        return BuildingAuthorityType.MANAGE_TAGS;
      case 'Users':
        return BuildingAuthorityType.MANAGE_USERS;
      case 'Roles':
        return BuildingAuthorityType.MANAGE_ROLES;
      case 'Schedules':
        return BuildingAuthorityType.MANAGE_SCHEDULES;
      case 'Emergency Lighting':
        return BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING;
      case 'Maintenance':
        return BuildingAuthorityType.MAINTENANCE;
      case 'Maintenance Updates':
        return BuildingAuthorityType.MANAGE_MAINTENANCE_UPDATES;
      case 'All token':
        return BuildingAuthorityType.ALL_TOKEN;
      case 'Unknown':
        return BuildingAuthorityType.UNKNOWN;
      case 'Manage DDL':
        return BuildingAuthorityType.MANAGE_DDL;
      case 'Manage Tenants':
        return BuildingAuthorityType.MANAGE_TENANT;
      case 'View Dashboard Insights':
        return BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS;
    }
  }

  static fromString(value: string): BuildingAuthorityType {
    switch (value) {
      case 'ALL':
        return BuildingAuthorityType.ALL;
      case 'VIEW':
        return BuildingAuthorityType.VIEW;
      case 'CONTROL_LIGHTS':
        return BuildingAuthorityType.CONTROL_LIGHTS;
      case 'ANALYTICS':
        return BuildingAuthorityType.ANALYTICS;
      case 'HIGH_RESOLUTION_ANALYTICS':
        return BuildingAuthorityType.HIGH_RESOLUTION_ANALYTICS;
      case 'MANAGE_CIRCADIAN_RHYTHM':
        return BuildingAuthorityType.MANAGE_CIRCADIAN_RHYTHM;
      case 'MANAGE_NODES':
        return BuildingAuthorityType.MANAGE_NODES;
      case 'MANAGE_FLOORS':
        return BuildingAuthorityType.MANAGE_FLOORS;
      case 'MANAGE_GATEWAYS':
        return BuildingAuthorityType.MANAGE_GATEWAYS;
      case 'MANAGE_BACNET_AREAS':
        return BuildingAuthorityType.MANAGE_BACNET_AREAS;
      case 'MANAGE_BLUETOOTH_BEACONS':
        return BuildingAuthorityType.MANAGE_BLUETOOTH_BEACONS;
      case 'MANAGE_LIGHTING_CONFIGURATION':
        return BuildingAuthorityType.MANAGE_LIGHTING_CONFIGURATION;
      case 'MANAGE_TAGS':
        return BuildingAuthorityType.MANAGE_TAGS;
      case 'MANAGE_USERS':
        return BuildingAuthorityType.MANAGE_USERS;
      case 'MANAGE_ROLES':
        return BuildingAuthorityType.MANAGE_ROLES;
      case 'MANAGE_BUILDING':
        return BuildingAuthorityType.MANAGE_BUILDING;
      case 'MANAGE_SCHEDULES':
        return BuildingAuthorityType.MANAGE_SCHEDULES;
      case 'MANAGE_EMERGENCY_LIGHTING':
        return BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING;
      case 'MAINTENANCE':
        return BuildingAuthorityType.MAINTENANCE;
      case 'MANAGE_MAINTENANCE_UPDATES':
        return BuildingAuthorityType.MANAGE_MAINTENANCE_UPDATES;
      case 'ALL_Token':
        return BuildingAuthorityType.ALL_TOKEN;
      case 'MANAGE_DDL':
        return BuildingAuthorityType.MANAGE_DDL;
      case 'MANAGE_TENANT':
        return BuildingAuthorityType.MANAGE_TENANT;
      case 'VIEW_DASHBOARD_INSIGHTS':
        return BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS;
    }
  }

  static fromArray(auths: BuildingAuthorityType_[]): BuildingAuthorityType[] {
    return auths.map((auth) => BuildingAuthorityType.fromString(auth));
  }

  static all(): BuildingAuthorityType[] {
    return [
      BuildingAuthorityType.ALL,
      BuildingAuthorityType.VIEW,
      BuildingAuthorityType.CONTROL_LIGHTS,
      BuildingAuthorityType.ANALYTICS,
      BuildingAuthorityType.HIGH_RESOLUTION_ANALYTICS,
      BuildingAuthorityType.MANAGE_CIRCADIAN_RHYTHM,
      BuildingAuthorityType.MANAGE_NODES,
      BuildingAuthorityType.MANAGE_FLOORS,
      BuildingAuthorityType.MANAGE_GATEWAYS,
      BuildingAuthorityType.MANAGE_BACNET_AREAS,
      BuildingAuthorityType.MANAGE_BLUETOOTH_BEACONS,
      BuildingAuthorityType.MANAGE_LIGHTING_CONFIGURATION,
      BuildingAuthorityType.MANAGE_TAGS,
      BuildingAuthorityType.MANAGE_USERS,
      BuildingAuthorityType.MANAGE_ROLES,
      BuildingAuthorityType.MANAGE_BUILDING,
      BuildingAuthorityType.MANAGE_SCHEDULES,
      BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING,
      BuildingAuthorityType.MAINTENANCE,
      BuildingAuthorityType.MANAGE_MAINTENANCE_UPDATES,
      BuildingAuthorityType.ALL_TOKEN,
      BuildingAuthorityType.MANAGE_DDL,
      BuildingAuthorityType.MANAGE_TENANT,
      BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS
    ];
  }

  constructor(public value: BuildingAuthorityType_, private name: BuildingAuthorityName_) {}

  toString(): string {
    return this.value;
  }

  getName(): BuildingAuthorityName_ {
    return this.name;
  }
}

type BuildingAuthorityType_ =
  | 'ALL'
  | 'VIEW'
  | 'CONTROL_LIGHTS'
  | 'ANALYTICS'
  | 'HIGH_RESOLUTION_ANALYTICS'
  | 'MANAGE_CIRCADIAN_RHYTHM'
  | 'MANAGE_NODES'
  | 'MANAGE_FLOORS'
  | 'MANAGE_GATEWAYS'
  | 'MANAGE_BACNET_AREAS'
  | 'MANAGE_BLUETOOTH_BEACONS'
  | 'MANAGE_LIGHTING_CONFIGURATION'
  | 'MANAGE_TAGS'
  | 'MANAGE_USERS'
  | 'MANAGE_ROLES'
  | 'MANAGE_BUILDING'
  | 'MANAGE_SCHEDULES'
  | 'MANAGE_EMERGENCY_LIGHTING'
  | 'MAINTENANCE'
  | 'MANAGE_MAINTENANCE_UPDATES'
  | 'ALL_TOKEN'
  | 'UNKNOWN'
  | 'MANAGE_DDL'
  | 'MANAGE_TENANT'
  | 'VIEW_DASHBOARD_INSIGHTS';

export type BuildingAuthorityName_ =
  | 'All'
  | 'View'
  | 'Control Lights'
  | 'Analytics'
  | 'High Resolution Analytics'
  | 'Circadian Curves'
  | 'Nodes'
  | 'Floors'
  | 'Gateways'
  | 'BACnet Areas'
  | 'Bluetooth Beacons'
  | 'Lighting Configuration'
  | 'Tags'
  | 'Users'
  | 'Roles'
  | 'Building'
  | 'Schedules'
  | 'Emergency Lighting'
  | 'Maintenance'
  | 'Maintenance Updates'
  | 'All token'
  | 'Unknown'
  | 'Manage DDL'
  | 'Manage Tenants'
  | 'View Dashboard Insights';
