import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { RefreshPageService } from '@services/refresh-page/refresh-page.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { NumericUtils } from '@app/shared/utils/numeric';
import { WidgetItem } from '@app/dashboard/model/widget-item';
import { BehaviorSubject, merge, Observable, Subject, switchMap, tap } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-count-compared-widget',
  standalone: true,
  imports: [NgClass, MatProgressSpinner, AsyncPipe, MatDivider],
  templateUrl: './count-compared-widget.component.html',
  styleUrl: './count-compared-widget.component.scss'
})
export class CountComparedWidgetComponent implements OnInit, OnDestroy {
  @Input({ required: true }) item: WidgetItem;
  @Input({ required: true }) refreshEvent: EventEmitter<WidgetItem>;
  private isLoadingSource = new BehaviorSubject(true);
  private destroy$ = new Subject<void>();
  countA: string;
  countB: string;

  constructor(private refreshPageService: RefreshPageService) {}

  ngOnInit(): void {
    merge(
      this.item.getData().pipe(tap(() => this.isLoadingSource.next(true))),
      this.refreshEvent.pipe(
        tap(() => this.isLoadingSource.next(true)),
        switchMap(() => this.item.getData())
      ),
      this.refreshPageService.pageRefresh$.pipe(
        tap(() => this.isLoadingSource.next(true)),
        switchMap(() => this.item.getData())
      )
    )
      .pipe(takeUntil(this.destroy$), delay(700))
      .subscribe((result: { currentYear: string; lastYear: string }) => {
        this.countA = NumericUtils.fixDigitsInValue(result.currentYear);
        this.countB = NumericUtils.fixDigitsInValue(result.lastYear);
        this.isLoadingSource.next(false);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSource.asObservable();
  }

  get prevYearText(): string {
    return `${new Date().getFullYear() - 1}`;
  }

  get currentYearText(): string {
    return `${new Date().getFullYear()}`;
  }
}
