import { QueryResult } from './../api/query/QueryResult';
import { IQueryExecutor, QueryExecutorConfiguration } from '../api/query/IQueryExecutor';
import { ComplexQueryOutline } from '../api/query/outline/ComplexQueryOutline';
import { SimpleQueryOutline } from '../api/query/outline/SimpleQueryOutline';
import { LiveQueryOutline } from '../api/query/outline/LiveQueryOutline';
import { PromiseUtils } from './promises/PromiseUtils';
import { PromiseRejectionHandler } from '../promises/PromiseRejectionHandler';
import angular from 'angular';
import { LiveDataQueryResults } from '@angularjs/or/api/query/LiveDataQueryResults';
import { FeatureService } from '@services/feature.service';

const Deferred = function () {
  this.promise = new Promise(
    function (resolve, reject) {
      this.resolve = resolve;
      this.reject = reject;
    }.bind(this)
  );
  this.then = this.promise.then.bind(this.promise);
  this.catch = this.promise.catch.bind(this.promise);
};

export class QueryExecutor implements IQueryExecutor {
  private canceller;
  private nodeDataCanceller;

  constructor(
    private $http: angular.IHttpService,
    private configuration: QueryExecutorConfiguration,
    private rejectionHandler: PromiseRejectionHandler
  ) {}

  public doComplexQuery(
    outline: ComplexQueryOutline
  ): Promise<QueryResult<number>> {
    let url = this.configuration.complexQueryUrl;
    return PromiseUtils.wrapSingle(
      this.$http.post(url, outline),
      this.rejectionHandler
    );
  }

  public doLiveQuery(outline: LiveQueryOutline): Promise<QueryResult<number>> {
    this.canceller = new Deferred();
    let url = this.configuration.liveQueryUrl;
    return PromiseUtils.wrapSingle(
      this.$http.post(url, outline, {
        timeout: this.canceller.promise
      }),
      this.rejectionHandler
    );
  }

  public cancelLiveQuery() {
    this.canceller?.resolve();
  }

  public cancelNodeDataQuery() {
    this.nodeDataCanceller?.resolve();
  }

  doLiveDataQuery(outline: LiveQueryOutline): Promise<LiveDataQueryResults> {
    this.nodeDataCanceller = new Deferred();
    let url = this.configuration.liveDataQueryUrl;
    return PromiseUtils.wrapSingle(
      this.$http.post(url, outline, {
        timeout: this.nodeDataCanceller.promise
      }),
      this.rejectionHandler
    );
  }
}
