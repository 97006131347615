import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { Role } from '@app/shared/models/role.interface';
import { IRoleResource } from '@app/shared/resources/role.resource.interface';

@Injectable({
  providedIn: 'root'
})
export class RoleResource extends Resource<Role, number> implements IRoleResource {
  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    super(http, `${environment.apiUrl + environment.apiPath}/role`);
  }

  getAllForBuilding(buildingId: number): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.baseUrl}/${buildingId}`);
  }

  addRole(buildingId: number, role: Role): Observable<Role> {
    return this.http.post<Role>(`${this.baseUrl}/${buildingId}`, role);
  }

  updateRole(role: Role): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${role.id}`, role);
  }
}
