import { SensorNode } from '@angularjs/or/api/building/SensorNode';
import { LuminaireDriver } from '@angularjs/or/api/building/LuminaireDriver';
import { LuminaireDriverResource } from '@angularjs/or/angular/resources/LuminaireDriverResource';

export class LuminaireDriverService {
  constructor(private luminaireDriverResource: LuminaireDriverResource) {}

  public getByNodeIds(nodeIds: number[]): Promise<LuminaireDriver[]> {
    return this.luminaireDriverResource.getByNodeIds(nodeIds);
  }

  public getByNodes(nodes: SensorNode[]): Promise<LuminaireDriver[]> {
    return this.luminaireDriverResource.getByNodeIds(
      nodes.map((node) => node.id)
    );
  }

  public getByNodeId(nodeId: number): Promise<LuminaireDriver[]> {
    return this.luminaireDriverResource.getByNodeId(nodeId);
  }

  public getByNode(node: SensorNode): Promise<LuminaireDriver[]> {
    return this.luminaireDriverResource.getByNodeId(node.id);
  }
}
