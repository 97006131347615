<div class="flex justify-end pr-[10px]">
  <app-refresh-page [showAutoRefresh]="true"></app-refresh-page>
</div>
<div id="widgets-grid" data-cy="widgets-grid" class="widgets-grid">
  <gridster [options]="gridOptions" class="bg-portal">
    @for (item of dashboard; track $index) {
      <gridster-item class="grid-item" [item]="item">
        <div class="h-full flex flex-col">
          <div class="flex justify-end max-h-10">
            @if (item.helpText) {
              <i class='material-icons mt-1 pt-3' [attr.data-cy]="item.dataCy + '-tooltip'" [matTooltip]='item.helpText'>help</i>
            }
            <button (mousedown)="refreshWidget($event, item)" (touchstart)="refreshWidget($event, item)" mat-icon-button [attr.data-cy]="item.dataCy + '-refresh-btn'">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          <div id="widget-container" data-cy="widget-container">
            <div class="h-full">
              @switch (item.type) {
                @case ('count') {
                  <app-count-widget [item]="item" [refreshEvent]='refreshEvent'/>
                }
                @case ('chart') {
                  <app-chart-widget [item]="item" [chartType]="item.chartType" [refreshEvent]='refreshEvent'/>
                }
                @default {
                  <app-count-widget [item]="item" [refreshEvent]='refreshEvent'/>
                }
              }
            </div>
          </div>
        </div>
      </gridster-item>
    }
  </gridster>
</div>