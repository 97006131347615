import { Map } from './Map';
import { Pair } from './Pair';

export class MapUtils {
  public static toMap<K, V>(pairs: Pair<K, V>[]): Map<V> {
    const mapping = {} as Map<V>;
    pairs.forEach((pair) => (mapping[pair.key.toString()] = pair.value));
    return mapping;
  }

  public static toPairs<V>(map: Map<V>): Pair<string, V>[] {
    const pairs = [];

    for (const key in map) {
      pairs.push(new Pair<string, V>(key, map[key]));
    }

    return pairs;
  }
}
