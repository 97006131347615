import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {UserService} from "@app/shared/services/user/user.service";
import {catchError, finalize, map} from "rxjs/operators";
import {ManagingCompanyService} from "@app/shared/services/managing-company.service";
import {AuthMethod} from "@app/shared/models/auth-method";
import {ManagingCompany} from "@app/shared/models/building.interface";
import {GlobalAuthority} from "@app/shared/models/global-authority";
import {BuildingAuthorization} from "@app/shared/models/building-authorization.interface";
import {User} from "@angularjs/or/api/auth/User";

export class UserDatasource implements DataSource<UserOutline> {

    private usersSubject = new BehaviorSubject<UserOutline[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    private countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();

    constructor(private usersService: UserService,
                private managingCompanyService: ManagingCompanyService) {
    }

    loadUsers(filter: string,
              sortDirection: string,
              pageIndex: number,
              pageSize: number) {

        this.loadingSubject.next(true);
        combineLatest([this.managingCompanyService.getManagingCompanies(), this.usersService.findUsers(filter, sortDirection, pageIndex, pageSize)]).pipe(
            map(([all, users]) => {
                users.content = users.content.map(user => {
                    const managingCompany = all.filter(mc => mc.id === user.managingCompanyId)[0];
                    return UserOutline.fromUser(user, managingCompany);
                });
                return users;
            }),
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe((users) => {
            this.usersSubject.next(users.content);
            this.countSubject.next(users.totalElements);
        });

    }

    connect(collectionViewer: CollectionViewer): Observable<UserOutline[]> {
        return this.usersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.usersSubject.complete();
        this.loadingSubject.complete();
    }

}

export interface IUserOutline {
    id: number;
    name: string;
    pictureUrl: string;
    emailAddress: string;
    authKey: string;
    authMethod: AuthMethod;
    managingCompany?: ManagingCompany;
    globalAuthorities: GlobalAuthority[];
    buildingAuthorizations: BuildingAuthorization[];
    headline: string;
    joinedAt: string;
    tenantIds: number[];
}

export class UserOutline implements IUserOutline {

    constructor(
        public id: number,
        public name: string,
        public pictureUrl: string,
        public emailAddress: string,
        public authKey: string,
        public authMethod: AuthMethod,
        public globalAuthorities: GlobalAuthority[],
        public buildingAuthorizations: BuildingAuthorization[],
        public headline: string,
        public joinedAt: string,
        public tenantIds: number[],
        public managingCompany?: ManagingCompany,
    ) {
    }

    public static fromUser(user: User, managingCompany?: ManagingCompany) {
        return new UserOutline(user.id, user.name, user.pictureUrl, user.emailAddress, user.authKey, user.authMethod, user.globalAuthorities, user.buildingAuthorizations, user.headline, user.joinedAt, user.tenantIds, managingCompany || null);
    }

    public toUser() {
        return new User(this.id, this.name, this.pictureUrl, this.emailAddress, this.authKey, this.authMethod, this.managingCompany?.id || null, this.globalAuthorities, this.buildingAuthorizations, this.headline, this.joinedAt, this.tenantIds);
    }
}