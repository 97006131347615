import { ITyped } from '../util/ITyped';

export class DataType implements ITyped {
  constructor(
    public value: DataType_,
    public format: (value: number, suffix: string) => string = (value, suffix) =>
      value ? value.toFixed(2) + suffix : null
  ) {}

  static OCCUPANCY = new DataType('OCCUPANCY');
  static ENERGY_CONSUMPTION = new DataType('ENERGY_CONSUMPTION', (value, suffix) =>
    DataType.energyFormatter(value, suffix)
  );
  static LIGHT_LEVEL = new DataType('LIGHT_LEVEL');
  static DIM_LEVEL = new DataType('DIM_LEVEL');
  static PRESENCE = new DataType('PRESENCE');
  static CONNECTION_QUALITY = new DataType('CONNECTION_QUALITY');

  public static fromString(value: string): DataType {
    switch (value) {
      case 'OCCUPANCY':
        return DataType.OCCUPANCY;
      case 'ENERGY_CONSUMPTION':
        return DataType.ENERGY_CONSUMPTION;
      case 'LIGHT_LEVEL':
        return DataType.LIGHT_LEVEL;
      case 'DIM_LEVEL':
        return DataType.DIM_LEVEL;
      case 'PRESENCE':
        return DataType.PRESENCE;
      case 'CONNECTION_QUALITY':
        return DataType.CONNECTION_QUALITY;
    }
  }

  private static energyFormatter(value: number, suffix: string): string {
    if (!value) {
      return null;
    }

    if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + ' M' + suffix;
    }

    if (value >= 1000) {
      return (value / 1000).toFixed(2) + ' k' + suffix;
    }

    if (value < 0.01) {
      return value.toExponential(2) + ' ' + suffix;
    }

    return value.toFixed(2) + ' ' + suffix;
  }

  public static all(): DataType[] {
    return [
      DataType.PRESENCE,
      DataType.OCCUPANCY,
      DataType.DIM_LEVEL,
      DataType.LIGHT_LEVEL,
      DataType.ENERGY_CONSUMPTION,
      DataType.CONNECTION_QUALITY
    ];
  }
  public getType(): string {
    return 'DataType';
  }

  public toString(): string {
    return this.value;
  }

  public equals(other: DataType): boolean {
    return this.value == other.value;
  }
}

export type DataType_ =
  | 'OCCUPANCY'
  | 'ENERGY_CONSUMPTION'
  | 'LIGHT_LEVEL'
  | 'DIM_LEVEL'
  | 'PRESENCE'
  | 'CONNECTION_QUALITY';
