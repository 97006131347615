import * as ng from 'angular';
import { AnalyticsController } from './AnalyticsController';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { NavigationSectionInfo } from '@app/shared/services/navigation/navigation-section-info';

export class AnalyticsConfig {
  private static ROUTE: string;

  private static routeProvider($routeProvider: ng.route.IRouteProvider) {
    $routeProvider.when(AnalyticsConfig.ROUTE, {
      template: require('raw-loader!./analytics.html').default,
      controller: [
        '$routeParams',
        '$scope',
        'QueryOutlineBuilder',
        'QueryContext',
        'QueryContext',
        'FloorService',
        'BuildingService',
        'NavigationService',
        'AnalyticsNavigationInfo',
        'SensorNodeService',
        'QueryContext',
        'QueryExecutor',
        'SecurityService',
        AnalyticsController
      ],
      controllerAs: 'analytics'
    });
  }

  static run(
    navigationService: NavigationService,
    navigationServiceInfo: NavigationSectionInfo,
    urlGenerator: (buildingId?: number, floorId?: number) => string
  ) {
    navigationService.addSectionToSiteMap(new NavigationSection(navigationServiceInfo, urlGenerator));
  }

  static configure(route: string) {
    this.ROUTE = route;
    ng.module('portal')
      .config(['$routeProvider', this.routeProvider])
      .run(['NavigationService', 'AnalyticsNavigationInfo', 'BuildingUrlGenerator', this.run]);
  }
}
