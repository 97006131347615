<div id='orAdministrationRoles'>
  <div class='or-content'>
    <div class='or-content-body' *ngIf='!isBusy'>
      <ul class='or-stacked-items or-roles-list'>
        <li class='or-stacked-item or-roles-list-item' *ngFor='let role of roles; let i=index'>
          <app-form-role-details [building]='building'
                                 [role]='role'
                                 [index]='i'
                                 [isNew]='false'
                                 [isBusy]='isBusy'
                                 (valueChanged)='loadDetails()'>
          </app-form-role-details>
        </li>
        <li class='or-stacked-item or-roles-list-item'>
          <app-form-role-details [building]='building'
                                 [index]='0'
                                 [isNew]='true'
                                 [isBusy]='isBusy'
                                 (valueChanged)='loadDetails()'>
          </app-form-role-details>
        </li>
      </ul>
    </div>
  </div>
</div>
