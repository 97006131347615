import { OrMetricSelectorController } from './OrMetricSelectorController';

export class OrMetricSelectorDirective {
  constructor() {
    return {
      bindToController: true,
      controller: ['QueryContext', OrMetricSelectorController],
      controllerAs: 'metricSelector',
      replace: true,
      restrict: 'E',
      scope: {},
      template: require('raw-loader!./or-metric-selector.html').default
    };
  }
}
