import { Inject, Injectable } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { Environment, environmentToken } from '@environment';
import { IBuildingMetadataService } from './building-metadata.interface';

@Injectable({
  providedIn: 'root'
})
export class BuildingMetadataService implements IBuildingMetadataService {
  private readonly baseUrl: string;

  constructor(@Inject(environmentToken) environment: Environment) {
    const { apiUrl, apiPath } = environment;
    this.baseUrl = apiUrl + apiPath;
  }

  public thumbnailUrl(building: Building): string {
    return building.thumbnailImage
      ? `${this.baseUrl}/building/${building.id}/thumbnail?id=${building.thumbnailImage}`
      : undefined;
  }

  public analyticsUrl(building: Building, floor: Floor): string {
    let buildId = null;
    let floorId = null;
    if (building) {
      buildId = building.id;
    }
    if (floor) {
      floorId = floor.id;
    }
    return `/buildings/${buildId}/${floorId}/analytics`;
  }

  public adminUrl(id: number): string {
    return `/buildings/${id}/administration/building`;
  }

  dashboardUrl(id: number): string {
    return `/buildings/${id}/dashboard`;
  }

  public sensorNodeUrl(building: Building, floor: Floor): string {
    const buildId = building?.id ?? null;
    const floorId = floor?.id ?? null;
    return `/buildings/${buildId}/${floorId}/sensor-nodes`;
  }
}
