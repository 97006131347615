import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://tellus-staging-api.organicresponse.com',
  apiPath: '/api/v1',
  frontendUrl: 'https://tellus-staging.organicresponse.com',
  features: {
    'Tenant Roles': true,
    'Tenant Groups': true,
    filteredTags: true,
    tagsEverywhere: true,
    beaconNimway: true,
    setStatusFrequency: true,
    DDL: true,
    tenantUser: true,
    enableDashboard: true
  },
  queryMicroPath: 'analytics',
  elmtPollingInterval: 10000
};
