import * as d3 from 'd3';
import * as angular from 'angular';
import * as h337 from 'heatmap.js';
import { Observable } from '@angularjs/or/util/Observable';
import { FloorplanSensorNode } from '@angularjs/or/api/building/FloorplanSensorNode';
import { SensorNodeService } from '@angularjs/or/services/SensorNodeService';
import {
  IDataTypeContext,
  ISelectableContext
} from '@angularjs/or/api/query/outline/context/IContext';
import { HeatmapRenderer } from '@angularjs/or/view/heatmap/renderer/HeatmapRenderer';
import { Subject } from 'rxjs';
import { DataType } from '@angularjs/or/data/SensorNodeDataType';
import { IObservableModifiable } from '@angularjs/or/util/IObservable';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import HeatmapDataPoint = h337.HeatmapDataPoint;

export class OrHeatmapController {
  public isScaleNormalized: boolean;
  public nodes: FloorplanSensorNode[];
  public width: number;
  public height: number;
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private currentlyShowing: [FloorplanSensorNode[], number, number, number];

  private container: d3.Selection<any, any, any, any>;

  public nodesSub: Subject<FloorplanSensorNode[]>;

  constructor(
    private scope,
    private nodeService: SensorNodeService,
    private selectableContext: Observable<ISelectableContext>,
    private heatmapRenderer: HeatmapRenderer,
    private dataTypeContext: IObservableModifiable<IDataTypeContext>,
    private navigationService: NavigationService
  ) {}

  public $onInit(): void {
    this.container = d3.select('.or-floorplan-heatmap');
    const containerElement: any = this.container.node();
    this.canvas = document.createElement('canvas');
    this.canvas.style.cssText =
      'position:absolute;left:0;top:0;filter:blur(20px)brightness(1.7)saturate(1.3)contrast(1.3);';
    this.ctx = this.canvas.getContext('2d', {
      willReadFrequently: true,
      alpha: true
    });
    containerElement.style.position = 'relative';
    containerElement.appendChild(this.canvas);
    this.updateSize();
    this.render();
    this.scope.$watch('heatmap.isScaleNormalized', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.render();
      }
    });
    this.scope.$watch('heatmap.chartData.maximum', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.render();
      }
    });
    this.nodesSub.subscribe((nodes) => {
      this.nodes = nodes;
      this.render();
    });
    this.scope.$watch('[heatmap.width, heatmap.height]', () =>
      this.updateSize()
    );
  }

  private updateSize(): void {
    /*
     * Doing this check to work around an issue where somehow assigning to the canvas dimensions would make the
     * heatmap disappear...
     */
    if (this.canvas.width != this.width || this.canvas.height != this.height) {
      this.container.style('width', this.width);
      this.container.style('height', this.height);
      this.canvas.width = this.width;
      this.canvas.height = this.height;
      if (this.currentlyShowing) {
        this.render();
      }
    }
  }

  public render(): void {
    if (this.nodes != null) {
      const data =
        this.nodes.filter((node) => {
          // OTP-4311-Currently we are sending a 0 value for Presence data for passive nodes which causes holes in
          // the heatmap when the passive nodes can’t sense presence, so we should ignore their value
          // for presence data
          const isPassiveNodePresenceData =
            node.isPassiveNode &&
            DataType.PRESENCE.equals(this.dataTypeContext.value().dataType);
          if (node.value == null) {
            node.value = 0;
          }
          return !isPassiveNodePresenceData;
        }) || [];
      const max = this.isScaleNormalized
        ? this.nodeService.getMaximumValue(this.nodes)
        : 100;
      // tslint:disable-next-line:max-line-length
      const showingNext = [
        data,
        max,
        this.scope.heatmap.width,
        this.scope.heatmap.height
      ] as [FloorplanSensorNode[], number, number, number];

      if (!angular.equals(showingNext, this.currentlyShowing)) {
        this.currentlyShowing = angular.copy(showingNext);
        this.forceRender(data, max);
      }
    }
  }

  private forceRender(data: HeatmapDataPoint[], max: number): void {
    this.ctx.clearRect(0, 0, this.width, this.height);
    if (this.navigationService.getActiveSection().info.Id === 'analytics') {
      this.heatmapRenderer.render(
        data,
        this.canvas,
        this.ctx,
        max,
        this.width,
        this.height
      );
    }
  }
}
