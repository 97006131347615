<div id="orGlobalAdministrationUsers">
  <mat-form-field>
    <input matInput placeholder="Search users" #input>
  </mat-form-field>

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
  </div>

  <mat-table class="users-table mat-elevation-z8" [dataSource]="dataSource"
             matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header="id">#</mat-header-cell>
      <mat-cell *matCellDef="let user">{{user.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="avatar">
      <mat-header-cell *matHeaderCellDef>Avatar</mat-header-cell>
      <mat-cell class="avatar-cell" *matCellDef="let user">
        <img *ngIf="user.pictureUrl" class="or-icon-userpic or-rounded" src="{{user.pictureUrl}}" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
      <mat-cell class="name-cell" *matCellDef="let user">{{user.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <mat-header-cell *matHeaderCellDef mat-sort-header="emailAddress">Email Address</mat-header-cell>
      <mat-cell class="emailAddress-cell" *matCellDef="let user">{{user.emailAddress}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="managingCompany">
      <mat-header-cell *matHeaderCellDef>Managing Company</mat-header-cell>
      <mat-cell class="managingCompany-cell" *matCellDef="let user">{{user.managingCompany?.companyName || ''}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="authMethod">
      <mat-header-cell *matHeaderCellDef>Auth Method</mat-header-cell>
      <mat-cell class="authMethod-cell" *matCellDef="let user">{{user.authMethod}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="globalAuthorities">
      <mat-header-cell *matHeaderCellDef>Global Authorities</mat-header-cell>
      <mat-cell class="globalAuthorities-cell" *matCellDef="let user">
        <mat-chip-listbox *ngFor="let globalAuthority of user.globalAuthorities">
          <mat-chip-option>{{globalAuthority}}</mat-chip-option>
        </mat-chip-listbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell class="actions-cell" *matCellDef="let userOutline">
        <button mat-mini-fab (click)="editUser(userOutline)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="deleteUser(userOutline)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.counter$ | async" [pageSize]="10"
                 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>