import { InjectionToken } from '@angular/core';

export interface Environment {
  production: boolean;
  apiUrl: string;
  apiPath: string;
  frontendUrl: string;
  features: object;
  queryMicroPath: string;
  elmtPollingInterval: number;
}

export const environmentToken = new InjectionToken<Environment>(
  'environment-token'
);
