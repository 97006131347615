import { Injectable } from '@angular/core';
import { FloorplanTag, Tag } from '@app/shared/models/tag.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ANALYTICS_ROUTE,
  BEACONS_ROUTE,
  EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE,
  LIGHTING_CONFIGURATION_ROUTE,
  MAP_NODES_ROUTE,
  SENSORNODES_ROUTE
} from '@common/route-constants';
import { NavigationService } from '@services/navigation/navigation.service';
import { IEmergencyTest } from '@app/shared/resources/emergency-lighting-test.resource';
import { map } from 'rxjs/operators';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { EmDriver } from '@app/shared/models/em-driver';

export interface TestsSummary {
  failedFunctionTests: number;
  failedDurationTests: number;
  cancelledFunctionTests: number;
  cancelledDurationTests: number;
  totalFunctionTests: number;
  totalDurationTests: number;
}

/**
 * This service is created to act as a bridge layer between the floorplan and
 * all the components interacting with it. Roles of this service are
 * - Maintain state of buttons pressed on the action tray panel
 * - Maintain state of tags that have been selected
 * - Maintain state of elmt test results
 * Methods this service provides:
 * - tagToggled(tag: FloorplanTag): use this to update the selected tags list
 * - resetSelectedTags(): use this to reset the selected tags list to "null"
 */
@Injectable({
  providedIn: 'root'
})
export class FloorplanService {
  private cumulative: boolean;
  private areaSelection: boolean;
  private enableNodes: boolean;
  private selectedTagsList$ = new BehaviorSubject<Tag[]>(null);
  private latestTestResults = new BehaviorSubject<IEmergencyTest[]>([]);
  private unmappedVisible: boolean;
  private mappedNodesVisible: boolean;
  private showDrivers: boolean;
  private highlightEmergencyLights: boolean;
  private currentPageId: string;
  private addModeEnabled: boolean;
  private heatmapModeEnabled: boolean;
  private faultyNodesModeEnabled: boolean;
  private moveModeEnabled: boolean;
  private moveAllModeEnabled: boolean;
  private snMappingModeEnabled: boolean;
  private pnMappingModeEnabled: boolean;
  private pubSubConnections: boolean;
  private gatewayModeEnabled: boolean;

  constructor(private navService: NavigationService) {
    this.initializeActionTray();
  }

  initializeActionTray(): void {
    this.cumulative = false;
    this.areaSelection = false;
    this.enableNodes = true;
    this.unmappedVisible = false;
    this.mappedNodesVisible = true;
    this.addModeEnabled = false;
    this.heatmapModeEnabled = false;
    this.moveModeEnabled = false;
    this.currentPageId = this.navService.getActiveSection().info.Id;
    this.showDrivers = [EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.id, SENSORNODES_ROUTE.id].includes(this.currentPageId);
    this.highlightEmergencyLights = this.currentPageId === EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.id;
    this.pubSubConnections = [SENSORNODES_ROUTE.id, MAP_NODES_ROUTE.id].includes(this.currentPageId);
    this.faultyNodesModeEnabled = [
      SENSORNODES_ROUTE.id,
      MAP_NODES_ROUTE.id,
      EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.id
    ].includes(this.currentPageId);
  }

  get isAddModeEnabled(): boolean {
    return this.addModeEnabled;
  }

  set addMode(value: boolean) {
    this.addModeEnabled = value;
  }

  get isHeatmapModeActive(): boolean {
    return this.heatmapModeEnabled;
  }

  get arePubSubConnectionsEnabled(): boolean {
    return this.pubSubConnections;
  }

  set showPubSubConnections(value: boolean) {
    this.pubSubConnections = value;
  }

  set heatmapMode(value: boolean) {
    this.heatmapModeEnabled = value;
  }

  get isShowFaultyNodesModeActive(): boolean {
    return this.faultyNodesModeEnabled;
  }

  set faultyNodesMode(value: boolean) {
    this.faultyNodesModeEnabled = value;
  }

  get showMappedNodesMode(): boolean {
    return this.mappedNodesVisible;
  }
  set setMappedNodesMode(value: boolean) {
    this.mappedNodesVisible = value;
  }

  /** Undo Floorplan - To BE implemented */

  get isMoveModeActive(): boolean {
    return this.moveModeEnabled;
  }
  set moveMode(value: boolean) {
    this.moveModeEnabled = value;
  }

  get isMoveAllModeActive(): boolean {
    return this.moveAllModeEnabled;
  }
  set moveAllMode(value: boolean) {
    this.moveAllModeEnabled = value;
  }

  get isSensorNodeMappingModeActive(): boolean {
    return this.snMappingModeEnabled;
  }

  set sensorNodeMappingMode(value: boolean) {
    this.snMappingModeEnabled = value;
  }

  get isPnMappingModeActive(): boolean {
    return this.pnMappingModeEnabled;
  }

  set pnMappingMode(value: boolean) {
    this.pnMappingModeEnabled = value;
  }

  set gatewayMode(value: boolean) {
    this.gatewayModeEnabled = value;
  }

  get isGatewayModeActive(): boolean {
    return this.gatewayModeEnabled;
  }

  resetSelectedTags(): void {
    this.selectedTagsList$.next(null);
  }

  setLatestEmergencyResults(results: IEmergencyTest[]): void {
    this.latestTestResults.next(results);
  }

  get latestEmergencyResults(): Observable<IEmergencyTest[]> {
    return this.latestTestResults.asObservable();
  }

  get emergencyResultSummary(): Observable<TestsSummary> {
    return this.latestTestResults.pipe(
      map((results) => {
        const testsSummary: TestsSummary = {
          failedDurationTests: 0,
          failedFunctionTests: 0,
          cancelledDurationTests: 0,
          cancelledFunctionTests: 0,
          totalDurationTests: 0,
          totalFunctionTests: 0
        };
        results.forEach((result) => {
          const testState = EmergencyLightingTestState.getStateFromInput(result.state);
          const isFunctionalTest = EmergencyLightingTestType.isFunctionalTest(result.type);
          const testFailed = EmDriver.isStateFailedAndNotCancelled(testState);
          const testCancelled = EmDriver.isStateCancelled(testState);
          if (isFunctionalTest) {
            testsSummary.failedFunctionTests += testFailed ? 1 : 0;
            testsSummary.cancelledFunctionTests += testCancelled ? 1 : 0;
            testsSummary.totalFunctionTests++;
          } else {
            testsSummary.failedDurationTests += testFailed ? 1 : 0;
            testsSummary.cancelledDurationTests += testCancelled ? 1 : 0;
            testsSummary.totalDurationTests++;
          }
        });
        return testsSummary;
      })
    );
  }

  get isAnalyticsPage(): boolean {
    return this.currentPageId === ANALYTICS_ROUTE.id;
  }

  get isBeaconsPage(): boolean {
    return this.currentPageId === BEACONS_ROUTE.id;
  }

  get isSensorNodesPage(): boolean {
    return this.currentPageId === SENSORNODES_ROUTE.id;
  }

  get isElmtPage(): boolean {
    return this.currentPageId === EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE.id;
  }

  get isLightingConfigPage(): boolean {
    return this.currentPageId === LIGHTING_CONFIGURATION_ROUTE.id;
  }

  get isUnmappingPage(): boolean {
    return this.currentPageId === MAP_NODES_ROUTE.id;
  }

  get isCumulativeModeActive(): boolean {
    return this.cumulative;
  }

  get isAreaModeActive(): boolean {
    return this.areaSelection;
  }

  get isEnableAllNodesSelected(): boolean {
    return this.enableNodes;
  }

  get selectedTagsList(): Observable<Tag[]> {
    return this.selectedTagsList$.asObservable();
  }

  get isHighlightEmergencyLightsModeActive(): boolean {
    return this.highlightEmergencyLights;
  }

  get isUnmappedModeActive(): boolean {
    return this.unmappedVisible;
  }

  get isShowDriversModeActive(): boolean {
    return this.showDrivers;
  }

  set cumulativeMode(value: boolean) {
    this.cumulative = value;
  }

  set areaMode(value: boolean) {
    this.areaSelection = value;
  }

  set enableAllNodesMode(value: boolean) {
    this.enableNodes = value;
  }

  set unmappedMode(value: boolean) {
    this.unmappedVisible = value;
  }

  set showDriversMode(value: boolean) {
    this.showDrivers = value;
  }

  set highlightEmergencyLightsMode(value: boolean) {
    this.highlightEmergencyLights = value;
  }

  tagToggled(tag: FloorplanTag): void {
    tag.isActive ? this.addToSelectedTagsIfNotAlreadyPresent(tag) : this.removeFromSelectedTagsIfPresent(tag);
  }

  private addToSelectedTagsIfNotAlreadyPresent(tag: FloorplanTag): void {
    const currentTagsList = this.selectedTagsList$.value || [];
    if (currentTagsList.find((t) => t.id === tag.id) == null) {
      this.selectedTagsList$.next([...currentTagsList, tag]);
    }
  }

  private removeFromSelectedTagsIfPresent(tag: FloorplanTag): void {
    const currentTagsList = this.selectedTagsList$.value || [];
    const foundIdx = currentTagsList.findIndex((t) => t.id === tag.id);
    if (foundIdx !== -1) {
      currentTagsList.splice(foundIdx, 1);
      this.selectedTagsList$.next(currentTagsList);
    }
  }
}
